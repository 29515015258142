import { useAxios } from '@vue-composable/axios'
import useHttp from './useHttp'
import { useStore } from 'vuex'

/**
 * @typedef {Object} StaffStatus
 * @property {string} employee_id - Staff's employee id
 * @property {string|null} last_project - Last logged td project
 * @property {string|null} last_task - Last logged td task
 * @property {url|null} photo_url
 * @property {string} status
 * @property {string} status_color
 * @property {string} display_name
 * @property {string|null} top_priority
 * @property {number} display_priority
 * @property {url} channel - Staff's primary slack channel
 */

export default () => {
  const http = useHttp()
  const { client, data, error, exec, loading, status } = useHttp()

  /**
   * Fetch staffs td and fin status
   * @returns {StaffStatus} API response data
   */
  const fetchStaffsStatus = async () => {
    const resp = await http.exec({ method: 'GET', url: '/users/staffs-status/' })
    if (!http.error.value && resp?.data) {
      return resp.data
    }
  }

  const addUser = async (userData) => await exec({ url: '/users', method: 'POST', data: userData })
  const fetchUsers = async (page, perPage, filter) => {
    console.log(client.defaults.headers)
    const resp = await exec({
      url: '/users',
      params: { page: page || 0, perPage: perPage || 50, filter: filter || null }
    })
    console.log(resp)
    return resp
  }
  const checkEmail = async (email) => await exec({ url: '/users-by-email', params: { email } })

  const offboardEmployee = async (id, data) => await exec({ url: `/users/offboard/${id}/`, method: 'POST', data })

  return { addUser, checkEmail, data, error, fetchStaffsStatus, fetchUsers, offboardEmployee, loading, status }
}

export const test = () => {
  const store = useStore()
  const { client, data, error, exec, loading, status } = useAxios()

  client.defaults.baseURL = 'http://localhost:8000/api'
  Object.assign(client.defaults.headers.common, {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${store.state.authToken}`
  })

  const addUser = async (userData) => await exec({ url: '/users', method: 'POST', data: userData })
  const fetchUsers = async (page, perPage, filter) =>
    await exec({ url: '/users', params: { page: page || 0, perPage: perPage || 50, filter: filter || null } })
  const checkEmail = async (email) => await exec({ url: '/users-by-email', params: { email } })

  return { addUser, checkEmail, data, error, fetchUsers, loading, status }
}
