<template>
  <div class="avatar-list-item p-d-flex p-ai-center p-jc-start" v-tooltip.left="toolTip">
    <div class="p-d-flex p-ai-center p-mr-3" :class="[badgeStyle]">
      <Skeleton data-test="sk-avatar" v-if="loading" shape="circle" size="40px" />
      <Avatar data-test="avatar" v-else shape="circle" size="large" v-badge>
        <img data-test="avatar-image" v-if="image && imageLoaded" :src="image" @error="imageLoaded = false" />
        <i data-test="avatar-alt" v-else class="pi pi-user" style="padding: 3px" />
      </Avatar>
    </div>
    <div style="flex: 1">
      <div v-if="loading">
        <Skeleton data-test="sk-title" class="p-mb-1" width="80%" />
        <Skeleton data-test="sk-subtitle" width="60%" />
      </div>
      <div v-else>
        <div data-test="title" class="title">{{ title }}</div>
        <small data-test="subtitle" v-if="subtitle && !loading">{{ subtitle }}</small>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

const props = defineProps({
  image: { type: String, required: false },
  title: { type: String, required: false },
  subtitle: { type: String, required: false },
  loading: { type: Boolean, default: false },
  isActive: { type: Boolean, required: false },
  badgeColor: { type: String, required: false },
  tooltip: { type: String, required: false }
})

const badgeStyle = computed(() => `badge-${props.badgeColor?.toLowerCase()}`)

const toolTip = computed(() => {
  if (props.tooltip) {
    return { value: props.tooltip, class: 'custom-tooltip' }
  }
  return null
})

const imageLoaded = ref(true)
</script>

<style lang="scss">
.custom-tooltip .p-tooltip-text {
  font-size: 1rem;
}
</style>

<style lang="scss" scoped>
.avatar-list-item {
  padding: 0.3rem 0.3rem 0.3rem 0.5rem;
}
.avatar-list-item:hover {
  background-color: var(--surface-c);
  border-radius: 25px 2px 2px 25px;
  cursor: default;
  margin-left: 0.4rem;
}
.title {
  font-size: 1rem;
  color: #515c66;
}
:deep(.p-avatar .p-badge) {
  top: 85%;
  right: 15%;
  width: 0.9rem;
  height: 0.9rem;
  background-color: rgba(0, 0, 0, 0.38) !important;
  display: none;
}
.badge-active {
  :deep(.p-avatar .p-badge) {
    display: block !important;
    background-color: rgba(104, 159, 56, 0.85) !important;
  }
}
.badge-passive {
  :deep(.p-avatar .p-badge) {
    display: block !important;
  }
}
.badge-green {
  :deep(.p-avatar .p-badge) {
    display: block !important;
    background-color: rgba(104, 159, 56, 0.85) !important;
  }
}
.badge-yellow {
  :deep(.p-avatar .p-badge) {
    display: block !important;
    background-color: rgba(236, 210, 91, 0.85) !important;
  }
}
.badge-red {
  :deep(.p-avatar .p-badge) {
    display: block !important;
    background-color: rgba(218, 73, 73, 0.767) !important;
  }
}
.badge-grey {
  :deep(.p-avatar .p-badge) {
    display: block !important;
  }
}
</style>
