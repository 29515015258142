<template>
  <div></div>
</template>

<script>
import { useAuth0 } from '../plugins/auth'

export default {
  async beforeRouteEnter(to, from) {
    const auth = useAuth0()
    const isAuthenticated = await auth.isAuthenticated()
    return isAuthenticated ? { name: 'init' } : await auth.loginWithRedirect()
  }
}
</script>
