import useHttp from './useHttp'

/**
 * @typedef {Object} NotificationUser
 * @property {string} email
 * @property {string} employee_id
 */

/**
 * @typedef {Object} Notification
 * @property {number} id
 * @property {NotificationUser} actor
 * @property {NotificationUser} recipient
 * @property {boolean} unread
 * @property {string} verb
 * @property {string} description
 * @property {string} timestamp
 * @property {string} timesince
 */

/**
 * @typedef {Object} NotificationListResponse
 * @property {number} count
 * @property {Array<Notification>} data
 */

/**
 * @typedef {Object} NotificationCount
 * @property {number} count
 */

export default () => {
  const http = useHttp()

  /**
   * Fetch notifications data
   * @param {('all' | 'read' | 'unread')} [status=all] - Notification status
   * @returns {Promise<import('axios').AxiosResponse<NotificationListResponse>>}
   */
  const fetchNotifications = (status = 'all') => http.exec({ method: 'GET', url: `/notifications/list/${status}/` })

  /**
   * Mark notification as read
   * @param {string} idOrTopic
   * @returns {Promise<import('axios').AxiosResponse<NotificationCount>>}
   */
  const markAsRead = (idOrTopic = '') => http.exec({ method: 'PATCH', url: `/notifications/mark/read/${idOrTopic}/` })

  return {
    ...http,
    fetchNotifications,
    markAsRead
  }
}
