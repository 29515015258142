<template>
  <div
    data-test="layout"
    class="p-field"
    :class="{ 'p-input-filled': $appState.inputStyle === 'filled', 'p-col-12': autoLayout, 'p-md-6': autoLayout }"
  >
    <slot name="label">
      <label data-test="label" v-if="label">{{ fieldLabel }}</label>
    </slot>
    <Skeleton v-if="loading" :height="loadingHeight" />
    <slot v-else></slot>
    <span v-if="showHelp">
      <slot name="help">
        <small data-test="error" class="p-error p-mr-3" :id="error.$uid" v-for="error in errors" :key="error.$uid">{{
          error.$message
        }}</small>
      </slot>
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  label: { type: String, required: true },
  showHelp: { type: Boolean, default: false },
  errors: { type: Array, required: false },
  autoLayout: { default: true },
  loading: { default: false },
  loadingHeight: { type: String, default: '37px' },
  loadingLabel: { type: String, default: 'Loading' }
})

const fieldLabel = computed(() => (props.label ? props.label : props.loadingLabel))
</script>
