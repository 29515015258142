import { useAuth0 } from '../plugins/auth'
import { useAxios } from '@vue-composable/axios'
import { stringify } from 'qs'

export default () => {
  const auth = useAuth0()
  const axios = useAxios()

  axios.client.defaults.paramsSerializer = params => stringify(params, { indices: false })
  axios.client.defaults.baseURL = process.env.VUE_APP_SERVER_BASE_URL + '/api'
  Object.assign(axios.client.defaults.headers.common, {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  })
  axios.client.interceptors.request.use(
    async config => {
      const token = await auth.getTokenSilently()
      config.headers.common.Authorization = `Bearer ${token}`
      return config
    },
    err => {
      console.log(err)
      return Promise.reject(err)
    }
  )

  return axios
}
