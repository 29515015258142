import { createStore } from 'vuex'
import { formatNestedMenu } from '../utils/utilities'
import { types } from './mutation-types'

import useNotifications from '../hooks/useNotifications'

const state = {
  costCenters: [],
  selectedCostCenterId: null,
  smileys: ['&#x1F61E;', '&#x1F610;', '&#x1F603;'],
  user: null,
  initialized: false,
  intercomBooted: false,
  notifications: []
}

export const getters = {
  getSmileys: (state) => (index) => state.smileys[index] || '&#x003F;',
  costCenterIds: (state) => state.costCenters.map((cc) => cc.costCenterId),
  costCenterNames: (state) => state.costCenters.map((cc) => cc.name),
  getSelectedCostCenter: (state) => state.costCenters.find((cc) => cc.costCenterId === state.selectedCostCenterId),
  getCostCenterMenus: (state) =>
    state.costCenters
      .find((cc) => cc.costCenterId === state.selectedCostCenterId)
      ?.menus?.map((menu) => formatNestedMenu(menu)) || [],
  getCostCenterById: (state) => (id) => state.costCenters.find((cc) => id === cc.costCenterId),
  unreadNotifications: (state) => state.notifications.filter((n) => n.unread).length
}

export const mutations = {
  [types.INIT_USER_DATA](state, payload) {
    state.user = payload
  },
  [types.INIT_COST_CENTERS](state, payload) {
    if (!payload?.length) return
    state.costCenters = payload
    state.selectedCostCenterId = payload[0].costCenterId
  },
  [types.ADD_EMBED_MENU](state, payload) {
    const { costCenterId } = payload
    const costCenter = state.costCenters.find((el) => el.costCenterId === costCenterId)
    if (!costCenter) return
    costCenter.menus.push(payload)
  },
  [types.UPDATE_EMBED_MENU](state, payload) {
    // Get cost center
    const { costCenterId } = payload
    const costCenter = state.costCenters.find((el) => el.costCenterId === costCenterId)
    if (!costCenter) return

    // Get menu to update
    const { id } = payload
    const menu = costCenter.menus.find((menu) => menu.id === id)
    if (!menu) return
    Object.assign(menu, payload)
  },
  [types.DELETE_EMBED_MENU](state, payload) {
    const { id, costCenterId } = payload
    const costCenter = state.costCenters.find((el) => el.costCenterId === costCenterId)
    if (!costCenter) return

    costCenter.menus = costCenter.menus.filter((menu) => menu.id !== id)
  },
  [types.SELECT_COST_CENTER](state, id) {
    state.selectedCostCenterId = id
  },
  [types.INITIALIZED](state) {
    state.initialized = true
  },
  [types.SET_INTERCOM_STATE](state, value) {
    state.intercomBooted = value
  },
  [types.UPDATE_NOTIFICATIONS](state, value) {
    state.notifications = value
  }
}

export const actions = {
  [types.NOTIFICATIONS_FETCH](context, status) {
    const { fetchNotifications } = useNotifications()
    fetchNotifications(status)
      .then((resp) => {
        context.commit(types.UPDATE_NOTIFICATIONS, resp.data.data)
      })
      .catch((err) => console.log(err))
  }
}

export const store = createStore({
  state,
  getters,
  mutations,
  actions
})
