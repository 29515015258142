import { createRouter, createWebHistory } from 'vue-router'
import { authGuard } from './auth-guard'

const routes = [
  {
    path: '/',
    redirect: '/init'
  },
  {
    path: '/init',
    name: 'init',
    exact: true,
    component: () => import('../pages/AppInit.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/home',
    name: 'home',
    exact: true,
    component: () => import('../pages/Home.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/performance/:taskId',
    redirect: { name: 'not-found' },
    name: 'performance',
    component: () => import('../pages/Performance.vue'),
    props: true,
    beforeEnter: authGuard,
    meta: { permissions: ['report:view'] }
  },
  {
    path: '/report/accountability/:costCenterId',
    redirect: { name: 'not-found' },
    name: 'accountability',
    component: () => import('../pages/report/Accountability.vue'),
    props: true,
    beforeEnter: authGuard,
    meta: { permissions: ['report:view'] }
  },
  {
    path: '/report/activity/:costCenterId',
    redirect: { name: 'not-found' },
    name: 'activity',
    component: () => import('../pages/report/Activity.vue'),
    props: true,
    beforeEnter: authGuard,
    meta: { permissions: ['report:view'] }
  },
  {
    path: '/report/utilization/:costCenterId',
    redirect: { name: 'not-found' },
    name: 'utilization',
    component: () => import('../pages/report/Utilization.vue'),
    props: true,
    beforeEnter: authGuard,
    meta: { permissions: ['report:view'] }
  },
  {
    path: '/reporting/sod',
    redirect: { name: 'not-found' },
    name: 'sod',
    component: () => import('../pages/reporting/SOD.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'not-found' },
        name: 'create-sod',
        component: () => import('../pages/reporting/SODCreate.vue')
      },
      {
        path: '/reporting/sod/intermediate',
        redirect: { name: 'not-found' },
        name: 'intermidiate-sod',
        component: () => import('../pages/reporting/SODIntermidiate.vue')
      },
      {
        path: '/reporting/sod/summary',
        redirect: { name: 'not-found' },
        name: 'summary-sod',
        component: () => import('../pages/reporting/SODSummary.vue')
      }
    ],
    beforeEnter: authGuard,
    meta: { permissions: ['report:create'] }
  },
  {
    path: '/reporting/eod',
    name: 'eod',
    component: () => import('../pages/reporting/EOD.vue'),
    children: [
      {
        path: '',
        name: 'create-eod',
        redirect: { name: 'not-found' },
        component: () => import('../pages/reporting/EODCreate.vue')
      },
      {
        path: '/reporting/eod/intermediate',
        redirect: { name: 'not-found' },
        name: 'intermidiate-eod',
        component: () => import('../pages/reporting/EODIntermidiate.vue')
      },
      {
        path: '/reporting/eod/summary',
        redirect: { name: 'not-found' },
        name: 'summary-eod',
        component: () => import('../pages/reporting/EODSummary.vue')
      }
    ],
    beforeEnter: authGuard,
    meta: { permissions: ['report:create'] }
  },
  {
    path: '/reporting/eow',
    redirect: { name: 'not-found' },
    name: 'eow',
    component: () => import('../pages/reporting/EOW.vue'),
    children: [
      { path: '', name: 'create-eow', component: () => import('../pages/reporting/EOWCreate.vue') },
      {
        path: '/reporting/eow/summary',
        name: 'summary-eow',
        component: () => import('../pages/reporting/EOWSummary.vue')
      }
    ],
    beforeEnter: authGuard,
    meta: { permissions: ['report:create', 'report:eow:create'] }
  },
  {
    path: '/reporting/logs',
    redirect: { name: 'not-found' },
    name: 'report-logs-all',
    component: () => import('../pages/reporting/ReportLogs.vue'),
    beforeEnter: authGuard,
    meta: { permissions: ['report:manage'] }
  },
  {
    path: '/reporting/logs/:costCenterId',
    redirect: { name: 'not-found' },
    name: 'report-logs',
    component: () => import('../pages/reporting/ReportLogs.vue'),
    beforeEnter: authGuard,
    props: true,
    meta: { permissions: ['report:list'] }
  },
  {
    path: '/reporting/eod/:reportId',
    redirect: { name: 'not-found' },
    name: 'eod-detail',
    props: true,
    component: () => import('../pages/reporting/EODDetail.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/reporting/eow/:reportId',
    redirect: { name: 'not-found' },
    name: 'eow-detail',
    props: true,
    component: () => import('../pages/reporting/EOWDetail.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/staff/schedules/:costCenterId',
    redirect: { name: 'not-found' },
    name: 'staff-schedules',
    props: true,
    component: () => import('../pages/StaffSchedule.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/Login.vue'),
    meta: { public: true }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../pages/Logout.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/users',
    redirect: { name: 'not-found' },
    name: 'users',
    component: () => import('../pages/Users.vue'),
    beforeEnter: authGuard,
    meta: { permissions: ['user:list'] }
  },
  {
    path: '/forms',
    name: 'forms',
    component: () => import('../pages/forms/Forms.vue'),
    beforeEnter: authGuard,
    meta: { permissions: ['form:list'] }
  },
  {
    path: '/page-builder',
    name: 'page-builder',
    component: () => import('../pages/menu/MenuBuilder.vue'),
    beforeEnter: authGuard,
    meta: { permissions: ['menu:list'] }
  },
  {
    path: '/eow-builder',
    name: 'eow-builder',
    component: () => import('../pages/builder/EOWBuilder.vue'),
    beforeEnter: authGuard,
    meta: { permissions: ['eow:list'] }
  },
  {
    path: '/page/:menuId',
    redirect: { name: 'not-found' },
    name: 'menu-content',
    props: true,
    component: () => import('../pages/menu/MenuContent.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/team-members/:costCenterId',
    redirect: { name: 'not-found' },
    name: 'team-members',
    props: true,
    component: () => import('../pages/TeamMembers.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/connect/:connectType',
    redirect: { name: 'not-found' },
    name: 'connect-login',
    props: true,
    component: () => import('../pages/help/HelpLogin.vue'),
    meta: { public: true }
  },
  {
    path: '/partner/:division',
    redirect: { name: 'not-found' },
    name: 'partner-signup',
    props: true,
    component: () => import('../pages/Partners.vue'),
    meta: { public: true }
  },
  {
    path: '/help',
    name: 'help',
    redirect: { name: 'not-found' },
    component: () => import('../pages/help/Help.vue'),
    children: [
      {
        path: 'speakup',
        redirect: { name: 'not-found' },
        name: 'speakup',
        component: () => import('../pages/help/SpeakUp.vue')
      },
      {
        path: 'desk',
        redirect: { name: 'not-found' },
        name: 'showup',
        component: () => import('../pages/help/ShowUp.vue')
      },
      {
        path: 'showup',
        redirect: { name: 'not-found' }
      }
    ],
    beforeEnter: authGuard
  },
  {
    path: '/help/engage',
    redirect: { name: 'not-found' },
    name: 'engage',
    component: () => import('../pages/help/Engage.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/help/bk',
    name: 'bookkeeping',
    component: () => import('../pages/help/Bookkeeping.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/newhireaccountcreation',
    name: 'new-hire-account-creation',
    component: () => import('../pages/help/NewHireAccountCreation.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/reports/:reportType/:param1',
    redirect: { name: 'not-found' },
    name: 'retool-adhoc-reports',
    props: true,
    component: () => import('../pages/report/retool/AdhocEmbed.vue'),
    children: [
      {
        path: ':param2',
        redirect: { name: 'not-found' },
        component: () => import('../pages/report/retool/AdhocEmbed.vue')
      }
    ],
    meta: { public: true }
  },
  {
    path: '/dialpad',
    name: 'dialpad',
    component: () => import('../pages/Dialpad.vue'),
    meta: { public: true }
  },
  {
    path: '/em/killswitch',
    name: 'killswitch',
    component: () => import('../pages/EmployeeKillSwitch.vue'),
    beforeEnter: authGuard,
    meta: { permissions: ['employees:offboard'] }
  },
  {
    path: '/access-denied',
    name: 'access-denied',
    component: () => import('../pages/Access.vue'),
    meta: { public: true }
  },
  {
    path: '/notfound',
    name: 'not-found',
    component: () => import('../pages/NotFound.vue'),
    meta: { public: true }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/notfound'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
