<template>
  <RightPanelCard
    data-test="panel-card"
    id="whos-out-panel"
    emptyMessage="No scheduled time-off for this week"
    :data="whosOutData"
    :loading="initialLoading || !costCenterId"
    :buttonEnabled="allowLoadMore"
    :buttonLoading="loading"
    @load-more="handleLoadMore"
  >
    <template #title>
      <div class="p-d-flex p-ai-center p-jc-between">
        <div data-test="title">Who's Out</div>
        <i data-test="icon" class="pi pi-calendar-minus" style="font-size: 1.3rem" />
      </div>
    </template>
  </RightPanelCard>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { uniqBy } from 'lodash-es'

import RightPanelCard from './RightPanelCard.vue'
import useBhr from '../../hooks/useBhr'
import { parseWhosOutList } from '../../utils/utilities'

const props = defineProps({ costCenterId: String })
const { allowLoadMore, loadMore, loading, fetchWhosOut, whosOut: fetchData } = useBhr()

const MAX_LOAD_MORE_COUNT = 2
const NUM_DAYS_PER_LOAD_MORE = 30

const data = ref([])
const initialLoading = ref(true)
let loadMoreCount = 0

// Dedupe who's out per day
// TODO: Handle this in the database level?
const whosOutData = computed(() => data.value.map((el) => ({ ...el, items: uniqBy(el.items, 'title') })))

watch(fetchData, (value) => {
  if (!value?.length) return
  const list = value.map((el) => parseWhosOutList(el))
  data.value.push(...list)
})

const handleLoadMore = async () => {
  await loadMore(fetchWhosOut, {
    costCenterId: props.costCenterId,
    numDays: NUM_DAYS_PER_LOAD_MORE
  })
  loadMoreCount++
  if (loadMoreCount >= MAX_LOAD_MORE_COUNT) {
    allowLoadMore.value = false
  }
}

// created
;(async () => {
  await fetchWhosOut(props.costCenterId)
  initialLoading.value = false
})()
</script>

<style lang="scss" scoped>
#whos-out-panel {
  max-height: 25.5rem;
}
</style>
