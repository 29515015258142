<template>
  <Loading :active="!initialized" loader="dots" color="#0288D1" />
  <div v-if="initialized" :class="layoutContainerClass" @click="onDocumentClick">
    <AppTopBar
      data-test="app-top-bar"
      :horizontal="menuMode === 'horizontal'"
      :topbarMenuActive="topbarMenuActive"
      :activeTopbarItem="activeTopbarItem"
      :mobileTopbarActive="mobileTopbarActive"
      @topbar-mobileactive="onTopbarMobileButtonClick"
      @menubutton-click="onMenuButtonClick"
      @topbar-menubutton-click="onTopbarMenuButtonClick"
      @topbaritem-click="onTopbarItemClick"
      @rightpanel-button-click="isRightPanelVisible = !isRightPanelVisible"
      :searchActive="searchActive"
      @search-toggle="onSearchToggle"
      @search-click="onSearchClick"
      @search-hide="onSearchHide"
    ></AppTopBar>

    <div data-test="app-menu" class="menu-wrapper">
      <div class="p-d-flex" style="height: 100%">
        <div v-show="false" style="width: 4rem; padding-right: 0px">
          <AppMenuBar :isLoading="useInitLoading" />
        </div>
        <div style="width: 15rem" class="layout-menu-container" @click="onMenuClick">
          <AppInlineMenu
            v-if="inlineMenuPosition === 'bottom' || inlineMenuPosition === 'both'"
            v-model:active="inlineMenuBottomActive"
            @change-inlinemenu="onChangeInlineMenu"
            inlineMenuKey="bottom"
            :menuMode="menuMode"
          ></AppInlineMenu>
          <AppMenu
            :model="menu"
            :menuMode="menuMode"
            :active="menuActive"
            :mobileMenuActive="mobileMenuActive"
            :isSlimOrHorItemClick="isSlimOrHorItemClick"
            @menuitem-click="onMenuItemClick"
            @root-menuitem-click="onRootMenuItemClick"
          ></AppMenu>
          <div style="flex-grow: 1"></div>
          <WhosOnline v-if="false" />
        </div>
      </div>
    </div>

    <div data-test="app-main" class="layout-main">
      <div class="p-d-flex p-mb-6">
        <div ref="main" id="main" class="layout-content">
          <router-view :key="routerViewKey" />
        </div>
        <RightPanel v-if="false" v-model="isRightPanelVisible" />
      </div>
      <AppFooter v-if="false" data-test="app-footer" />
    </div>

    <AppConfig
      v-if="can('update', 'theme') && false"
      :menuMode="menuMode"
      @menu-mode-change="onMenuModeChange"
      @layout-mode-change="onLayoutModeChange"
      @menu-theme="onMenuTheme"
      :layoutMode="d_layoutMode"
      @topbar-theme="onTopbarThemeChange"
      v-model:inlineMenuPosition="inlineMenuPosition"
      @inlinemenu-change="onInlineMenuPositionChange"
      :theme="theme"
      :themes="themes"
      @theme-change="changeTheme"
      :menuTheme="d_menuTheme"
      :menuThemes="menuThemes"
      :topbarTheme="d_topbarTheme"
      :topbarThemes="topbarThemes"
    >
    </AppConfig>

    <AppRightPanel
      data-test="app-right-panel"
      :expanded="rightPanelActive"
      @content-click="onRightPanelClick"
      @hide="onHideClick"
    ></AppRightPanel>
    <div v-if="mobileMenuActive" class="layout-mask modal-in"></div>
  </div>
</template>

<script>
import { computed, inject, provide, ref, watch, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useAbility } from '@casl/vue'
import { useToast } from 'primevue/usetoast'

import AppTopBar from './AppTopbar.vue'
import AppRightPanel from './AppRightPanel.vue'
import AppInlineMenu from './AppInlineMenu.vue'
import AppMenu from './AppMenu.vue'
import AppMenuBar from './AppMenuBar.vue'
import AppConfig from './AppConfig.vue'
import AppFooter from './AppFooter.vue'
import EventBus from './event-bus'
import Loading from 'vue-loading-overlay'

import RightPanel from './RightPanel.vue'
import WhosOnline from './components/WhosOnline.vue'
import useInit from './hooks/useInit'
// import { types } from './store/mutation-types'
import { useAuth0 } from './plugins/auth'
import { handleHttpError } from './utils/utilities'
import { RIGHT_PANEL_VISIBLE, TRIGGER_RENDER_PATHS } from './definitions'

export default {
  emits: ['layout-mode-change', 'menu-theme', 'menuTheme', 'topbar-theme', 'topbarTheme', 'layoutModeChange'],
  props: {
    topbarTheme: String,
    menuTheme: String,
    layoutMode: String,
    componentTheme: String
  },
  setup(props) {
    const auth = useAuth0()
    const route = useRoute()
    const store = useStore()
    const toast = useToast()
    const { can } = useAbility()
    const { error, initialized, loading: useInitLoading, slackConfigured } = useInit()

    const main = ref(null)
    const menu = ref([])
    const isRightPanelVisible = ref(false)

    const setTheme = inject('setTheme')
    provide('slackConfigured', slackConfigured)
    provide('isRightPanelVisible', isRightPanelVisible)

    const selectedCostCenterId = computed(() => store.state.selectedCostCenterId)
    const selectedCostCenter = computed(() => store.getters.getSelectedCostCenter)
    const userType = computed(() => store.state.user?.type)
    // changing :key rerenders the view
    const routerViewKey = computed(() => (TRIGGER_RENDER_PATHS.includes(route.name) ? route.path : null))
    const costCenterMenus = computed(() => store.getters.getCostCenterMenus)
    // const costCenterNames = computed(() => store.getters.costCenterNames)

    watch(error, async (value) => {
      if (value?.response?.status === 401) {
        await auth.logout()
      }
      handleHttpError(value, toast)
    })

    watch(route, ({ matched }) => {
      isRightPanelVisible.value = matched.some((el) => {
        return RIGHT_PANEL_VISIBLE[userType.value]?.includes(el.name)
      })
    })

    watchEffect(() => {
      if (!userType.value) return
      isRightPanelVisible.value = route.matched.some((el) => RIGHT_PANEL_VISIBLE[userType.value]?.includes(el.name))
    })

    // Poll notifications
    // const stop = watch(initialized, (value) => {
    //   if (value) {
    //     stop()
    //     store.dispatch(types.NOTIFICATIONS_FETCH)
    //     // const intervalId = setInterval(() => store.dispatch(types.NOTIFICATIONS_FETCH), 5 * 1000)
    //     // onUnmounted(() => clearInterval(intervalId))
    //   }
    // })

    watchEffect(() => {
      menu.value = [
        {
          label: 'Admin',
          icon: 'pi pi-fw pi-star',
          items: [
            {
              label: 'EOD Builder',
              icon: 'pi pi-file',
              to: '/forms',
              visible: can('list', 'form') || can('create', 'form')
            },
            {
              label: 'EOW Builder',
              icon: 'pi pi-file',
              to: '/eow-builder',
              visible: can('list', 'eow') || can('create', 'eow')
            },
            {
              label: 'Page Builder',
              icon: 'pi pi-table',
              to: '/page-builder',
              visible: can('list', 'menu') || can('create', 'menu')
            },
            {
              label: 'SOD/EOD Logs',
              icon: 'pi pi-list',
              to: { name: 'report-logs-all' },
              visible: false
            }
          ],
          visible:
            can('list', 'form') ||
            can('create', 'form') ||
            can('list', 'eow') ||
            can('create', 'eow') ||
            can('list', 'menu') ||
            can('manage', 'report')
        }
      ]

      if (!selectedCostCenterId.value) return

      // Dynamically render menus on cost center change
      const items = [
        {
          label: 'SOD/EOD Logs',
          icon: 'pi pi-list',
          to: {
            name: 'report-logs',
            params: {
              costCenterId: selectedCostCenter.value?.costCenterId,
              costCenterName: selectedCostCenter.value?.name
            }
          },
          visible: can('list', 'report')
        },
        {
          label: 'Schedules',
          icon: 'pi pi-calendar',
          to: {
            name: 'staff-schedules',
            params: {
              costCenterId: selectedCostCenter.value?.costCenterId
            }
          }
        }
      ]

      // Reports dashboard
      const reports = [
        {
          label: 'Accountability',
          icon: 'pi pi-chart-line',
          to: { name: 'accountability', params: { costCenterId: selectedCostCenterId.value } },
          visible: false
        },
        {
          label: 'Tools & Resources',
          icon: 'pi pi-chart-line',
          to: { name: 'activity', params: { costCenterId: selectedCostCenterId.value } },
          visible: can('view', 'report')
        },
        {
          label: 'Utilization',
          icon: 'pi pi-chart-line',
          to: { name: 'utilization', params: { costCenterId: selectedCostCenterId.value } },
          visible: can('view', 'report')
        }
      ]

      // // Performance dashboard
      // if (selectedCostCenter.value?.kpis?.length) {
      //   reports.splice(1, 0, {
      //     label: 'Performance',
      //     icon: 'pi pi-chart-line',
      //     items: selectedCostCenter.value.kpis.map((kpi) => ({
      //       label: kpi.task_name,
      //       icon: 'pi pi-fw pi-align-left',
      //       to: { name: 'performance', params: { taskId: kpi.task_id } },
      //       visible: can('view', 'report')
      //     })),
      //     visible: can('view', 'report')
      //   })
      // }

      items.push({
        label: 'Reports',
        icon: 'pi pi-chart-bar',
        items: reports,
        visible: can('view', 'report')
      })

      // Team members
      items.push({
        label: 'Members',
        icon: 'pi pi-users',
        to: { name: 'team-members', params: { costCenterId: selectedCostCenter.value?.costCenterId } }
      })

      if (costCenterMenus.value?.length) {
        // Pages
        items.push({
          label: 'Pages',
          icon: 'pi pi-book',
          items: [...costCenterMenus.value]
        })
      }
      menu.value.push({
        label: selectedCostCenter.value?.company_name || selectedCostCenter.value?.name,
        icon: 'pi pi-users',
        items,
        visible: false
      })
    })

    const replaceLink = (linkElement, href, callback) => {
      const { BASE_URL } = process.env
      href = href.startsWith(BASE_URL) ? href : BASE_URL + href

      const id = linkElement.getAttribute('id')
      const cloneLinkElement = linkElement.cloneNode(true)

      cloneLinkElement.setAttribute('href', href)
      cloneLinkElement.setAttribute('id', id + '-clone')

      linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling)

      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove()
        cloneLinkElement.setAttribute('id', id)

        if (callback) {
          callback()
        }
      })
    }
    const changeStyleSheetUrl = (id, value) => {
      const element = document.getElementById(id)
      const urlTokens = element.getAttribute('href').split('/')
      urlTokens[urlTokens.length - 2] = value
      const newURL = urlTokens.join('/')
      replaceLink(element, newURL)
    }
    changeStyleSheetUrl('theme-css', props.componentTheme)

    return {
      can,
      changeStyleSheetUrl,
      initialized,
      main,
      menu,
      replaceLink,
      routerViewKey,
      route,
      setTheme,
      isRightPanelVisible,
      useInitLoading
    }
  },
  data() {
    return {
      d_topbarTheme: this.topbarTheme,
      d_menuTheme: this.menuTheme,
      d_layoutMode: this.layoutMode,
      mobileTopbarActive: false,
      mobileMenuActive: false,
      search: false,
      searchClick: false,
      searchActive: false,
      menuMode: 'static',
      inlineMenuClick: false,
      inlineMenuPosition: 'bottom',
      inlineMenuTopActive: false,
      inlineMenuBottomActive: false,
      overlayMenuActive: false,
      rotateMenuButton: false,
      topbarMenuActive: false,
      activeTopbarItem: null,
      isSlimOrHorItemClick: false,
      darkMenu: false,
      theme: this.componentTheme,
      themes: [
        { name: 'indigo', color: '#2f8ee5' },
        { name: 'pink', color: '#E91E63' },
        { name: 'purple', color: '#9C27B0' },
        { name: 'deeppurple', color: '#673AB7' },
        { name: 'blue', color: '#2196F3' },
        { name: 'lightblue', color: '#03A9F4' },
        { name: 'cyan', color: '#00BCD4' },
        { name: 'teal', color: '#009688' },
        { name: 'green', color: '#4CAF50' },
        { name: 'lightgreen', color: '#8BC34A' },
        { name: 'lime', color: '#CDDC39' },
        { name: 'yellow', color: '#FFEB3B' },
        { name: 'amber', color: '#FFC107' },
        { name: 'orange', color: '#FF9800' },
        { name: 'deeporange', color: '#FF5722' },
        { name: 'brown', color: '#795548' },
        { name: 'bluegrey', color: '#607D8B' }
      ],
      menuThemes: [
        { name: 'light', color: '#FDFEFF' },
        { name: 'dark', color: '#434B54' },
        { name: 'indigo', color: '#1A237E' },
        { name: 'bluegrey', color: '#37474F' },
        { name: 'brown', color: '#4E342E' },
        { name: 'cyan', color: '#006064' },
        { name: 'green', color: '#2E7D32' },
        { name: 'deeppurple', color: '#4527A0' },
        { name: 'deeporange', color: '#BF360C' },
        { name: 'pink', color: '#880E4F' },
        { name: 'purple', color: '#6A1B9A' },
        { name: 'teal', color: '#00695C' }
      ],
      topbarThemes: [
        { name: 'lightblue', color: '#2E88FF' },
        { name: 'dark', color: '#363636' },
        { name: 'white', color: '#FDFEFF' },
        { name: 'blue', color: '#1565C0' },
        { name: 'deeppurple', color: '#4527A0' },
        { name: 'purple', color: '#6A1B9A' },
        { name: 'pink', color: '#AD1457' },
        { name: 'cyan', color: '#0097A7' },
        { name: 'teal', color: '#00796B' },
        { name: 'green', color: '#43A047' },
        { name: 'lightgreen', color: '#689F38' },
        { name: 'lime', color: '#AFB42B' },
        { name: 'yellow', color: '#FBC02D' },
        { name: 'amber', color: '#FFA000' },
        { name: 'orange', color: '#FB8C00' },
        { name: 'deeporange', color: '#D84315' },
        { name: 'brown', color: '#5D4037' },
        { name: 'grey', color: '#616161' },
        { name: 'bluegrey', color: '#546E7A' },
        { name: 'indigo', color: '#3F51B5' }
      ],
      rightPanelActive: false,
      menuActive: true
    }
  },
  watch: {
    $route() {
      this.menuActive = this.isStatic() && !this.isMobile()
      this.$toast.removeAllGroups()
    },
    topbarTheme(newValue) {
      this.d_topbarTheme = newValue
    },
    menuTheme(newValue) {
      this.d_menuTheme = newValue
    },
    layoutMode(newValue) {
      this.d_layoutMode = newValue
    }
  },
  methods: {
    onDocumentClick() {
      if (!this.searchClick && this.searchActive) {
        this.onSearchHide()
      }

      if (!this.topbarItemClick) {
        this.activeTopbarItem = null
        this.topbarMenuActive = false
      }

      if (!this.menuClick) {
        if (this.isHorizontal() || this.isSlim()) {
          this.menuActive = false
          this.isSlimOrHorItemClick = false
          EventBus.emit('reset-active-index')
        }

        if (this.isOverlay()) {
          this.menuActive = false
        }

        this.hideOverlayMenu()
        this.unblockBodyScroll()
      }

      if (!this.rightPanelClick) {
        this.rightPanelActive = false
      }

      if (!this.inlineMenuClick) {
        this.inlineMenuTopActive = false
        this.inlineMenuBottomActive = false
      }

      this.topbarItemClick = false
      this.menuClick = false
      this.rightPanelClick = false
      this.searchClick = false
      this.inlineMenuClick = false
    },
    onSearchToggle() {
      this.searchActive = !this.searchActive
      this.searchClick = true
    },
    onSearchClick() {
      this.searchClick = true
    },
    onSearchHide() {
      this.searchActive = false
      this.searchClick = false
    },
    isHorizontal() {
      return this.menuMode === 'horizontal'
    },
    isSlim() {
      return this.menuMode === 'slim'
    },
    isOverlay() {
      return this.menuMode === 'overlay'
    },
    isStatic() {
      return this.menuMode === 'static'
    },
    isDesktop() {
      return window.innerWidth > 991
    },
    isMobile() {
      return window.innerWidth <= 991
    },
    hideOverlayMenu() {
      this.rotateMenuButton = false
      this.overlayMenuActive = false
      this.mobileMenuActive = false
    },
    onMenuButtonClick(event) {
      this.menuClick = true
      this.menuActive = !this.menuActive
      this.topbarMenuActive = false
      this.topbarRightClick = true
      this.rotateMenuButton = !this.rotateMenuButton
      this.topbarMenuActive = false

      if (!this.isDesktop()) {
        this.mobileMenuActive = !this.mobileMenuActive
        if (this.mobileMenuActive) {
          this.blockBodyScroll()
        } else {
          this.unblockBodyScroll()
        }
      }

      event.preventDefault()
    },
    onTopbarMenuButtonClick(event) {
      this.topbarItemClick = true
      this.topbarMenuActive = !this.topbarMenuActive
      this.hideOverlayMenu()
      event.preventDefault()
    },
    onTopbarItemClick(event) {
      this.topbarItemClick = true

      if (this.activeTopbarItem === event.item) this.activeTopbarItem = null
      else this.activeTopbarItem = event.item

      event.originalEvent.preventDefault()
    },
    onTopbarMobileButtonClick(event) {
      this.mobileTopbarActive = !this.mobileTopbarActive
      event.preventDefault()
    },
    onRightPanelButtonClick(event) {
      this.rightPanelClick = true
      this.rightPanelActive = !this.rightPanelActive

      event.preventDefault()
    },
    onRightPanelClick() {
      this.rightPanelClick = true
    },
    onHideClick(expanded) {
      this.rightPanelActive = expanded
    },
    onMenuClick() {
      this.menuClick = true
    },
    onRootMenuItemClick(event) {
      if (event.isSameIndex) {
        this.isSlimOrHorItemClick = false
      } else {
        this.isSlimOrHorItemClick = true
      }
      this.menuActive = !this.menuActive
    },
    onMenuItemClick(event) {
      if (!event.item.items) {
        this.isSlimOrHorItemClick = false
        this.hideOverlayMenu()
        EventBus.emit('reset-active-index')
      }

      if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
        this.menuActive = false
      }
    },
    onMenuModeChange(menuMode) {
      this.menuMode = menuMode
      this.overlayMenuActive = false

      if (menuMode === 'static') {
        this.menuActive = true
      }

      if (menuMode === 'horizontal') {
        this.inlineMenuPosition = 'bottom'
      }
    },
    onLayoutModeChange(menuColor) {
      this.$emit('layout-mode-change', menuColor)

      const layoutLink = document.getElementById('layout-css')
      const layoutHref = 'assets/layout/css/layout-' + menuColor + '.css'
      this.replaceLink(layoutLink, layoutHref)

      const themeLink = document.getElementById('theme-css')
      const urlTokens = themeLink.getAttribute('href').split('/')
      urlTokens[urlTokens.length - 1] = 'theme-' + menuColor + '.css'
      const newURL = urlTokens.join('/')

      this.replaceLink(themeLink, newURL, () => {
        this.$appState.isNewThemeLoaded = true
      })
    },
    onInlineMenuPositionChange(position) {
      this.inlineMenuPosition = position
    },
    onChangeInlineMenu(e, key) {
      if (key === 'top') {
        if (this.inlineMenuBottomActive) {
          this.inlineMenuBottomActive = false
        }
        this.inlineMenuTopActive = !this.inlineMenuTopActive
      }
      if (key === 'bottom') {
        if (this.inlineMenuTopActive) {
          this.inlineMenuTopActive = false
        }
        this.inlineMenuBottomActive = !this.inlineMenuBottomActive
      }

      this.inlineMenuClick = true
    },
    changeTheme(theme) {
      this.theme = theme
      this.setTheme(theme)
      this.changeStyleSheetUrl('theme-css', theme)
    },
    onTopbarThemeChange(theme) {
      this.$emit('topbar-theme', theme)
    },
    onMenuTheme(menuTheme) {
      this.$emit('menu-theme', menuTheme)
    },
    blockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.add('blocked-scroll')
      } else {
        document.body.className += ' blocked-scroll'
      }
    },
    unblockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.remove('blocked-scroll')
      } else {
        document.body.className = document.body.className.replace(
          new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'),
          ' '
        )
      }
    }
  },
  computed: {
    layoutContainerClass() {
      return [
        'layout-wrapper',
        'layout-menu-' + this.menuTheme + ' layout-topbar-' + this.topbarTheme,
        {
          'layout-menu-static': this.menuMode === 'static',
          'layout-menu-overlay': this.menuMode === 'overlay',
          'layout-menu-overlay-active': this.overlayMenuActive,
          'layout-menu-slim': this.menuMode === 'slim',
          'layout-menu-horizontal': this.menuMode === 'horizontal',
          'layout-menu-active': this.menuActive,
          'layout-menu-mobile-active': this.mobileMenuActive,
          'layout-topbar-mobile-active': this.mobileTopbarActive,
          'layout-rightmenu-active': this.rightPanelActive,
          'layout-rtl': this.$appState.RTL,
          'p-input-filled': this.$appState.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false
        }
      ]
    }
  },
  components: {
    AppTopBar: AppTopBar,
    AppRightPanel: AppRightPanel,
    AppMenu: AppMenu,
    AppMenuBar: AppMenuBar,
    AppConfig: AppConfig,
    AppFooter: AppFooter,
    RightPanel: RightPanel,
    AppInlineMenu,
    WhosOnline,
    Loading
  }
}
</script>

<style lang="scss">
@import './App.scss';

.menu-wrapper {
  width: 19rem;

  .layout-menu-container {
    flex: 1;
  }
}

@media (max-width: 991px) {
  .layout-wrapper .menu-wrapper {
    transform: translate3d(-19rem, 0px, 0px);
  }
}

@media (min-width: 992px) {
  .layout-menu-static {
    .menu-wrapper {
      transform: translate3d(-19rem, 0px, 0px);
    }

    &.layout-menu-active {
      .layout-main {
        margin-left: 19rem;
      }
    }
  }
}
</style>
