<template>
  <div class="layout-topbar p-shadow-4">
    <div class="layout-topbar-left">
      <a class="layout-topbar-logo" href="/">
        <h1 class="company-logo">Go2</h1>
      </a>

      <a v-show="false" class="layout-menu-button p-shadow-6 p-ripple" @click="onMenuButtonClick($event)" v-ripple>
        <i class="pi pi-chevron-right"></i>
      </a>

      <a class="layout-topbar-mobile-button p-ripple" @click="onTopbarMobileButtonClick($event)" v-ripple>
        <i class="pi pi-ellipsis-v fs-large"></i>
      </a>
    </div>

    <div class="layout-topbar-right" :class="{ 'layout-topbar-mobile-active': mobileTopbarActive }">
      <div v-if="false" class="layout-topbar-actions-left">
        <!-- <MegaMenu :model="items" class="layout-megamenu"></MegaMenu> -->
        <div v-if="can('create', 'report')" class="p-d-flex p-ai-center p-pl-3 btn-reporting__container">
          <Button
            class="p-button-outlined btn-reporting p-text-bold p-px-5"
            label="SOD"
            :disabled="$route.matched.some(({ name }) => name === 'sod')"
            @click="$router.push({ name: 'sod' })"
          />
          <Button
            class="p-button-outlined btn-reporting p-text-bold p-px-5"
            label="EOD"
            :disabled="$route.matched.some(({ name }) => name === 'eod')"
            @click="$router.push({ name: 'eod' })"
          />
          <Button
            v-if="can('create', 'report:eow')"
            class="p-button-outlined btn-reporting p-text-bold p-px-5"
            label="EOW"
            :disabled="$route.matched.some(({ name }) => name === 'eow')"
            @click="$router.push({ name: 'eow' })"
          />
        </div>
      </div>

      <div v-if="false" class="layout-topbar-actions-right">
        <ul class="layout-topbar-items">
          <li v-show="false" class="layout-topbar-item layout-search-item">
            <a
              class="layout-topbar-action rounded-circle p-ripple"
              @click="onTopbarItemClick($event, 'search')"
              v-ripple
            >
              <i class="pi pi-search fs-large"></i>
            </a>

            <transition name="layout-search-panel" @enter="onEnter">
              <div class="layout-search-panel p-inputgroup" v-show="searchActive" @click="onSearchContainerClick">
                <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                <InputText ref="searchInput" type="text" placeholder="Search" @keydown="onSearchKeydown($event)" />
                <span class="p-inputgroup-addon">
                  <Button
                    icon="pi pi-times"
                    class="p-button-rounded p-button-text p-button-plain"
                    @click="changeSearchActive"
                  ></Button>
                </span>
              </div>
            </transition>
          </li>

          <li class="layout-topbar-item notifications">
            <a
              class="layout-topbar-action rounded-circle p-ripple"
              @click="onTopbarItemClick($event, 'notifications')"
              v-ripple
            >
              <span
                class="p-overlay-badge notif-bell"
                :class="{ 'badge-active': $store.getters.unreadNotifications }"
                v-badge.warning
              >
                <i class="pi pi-bell fs-large"></i>
              </span>
            </a>

            <transition name="layout-topbar-action-panel">
              <NotificationList v-show="activeTopbarItem === 'notifications'" />
            </transition>
          </li>

          <!-- <li class="layout-topbar-item app">
            <a class="layout-topbar-action rounded-circle p-ripple" @click="onTopbarItemClick($event, 'apps')" v-ripple>
              <i class="pi pi-table fs-large"></i>
            </a>

            <transition name="layout-topbar-action-panel">
              <div class="p-grid p-nogutter layout-topbar-action-panel p-shadow-6" v-show="activeTopbarItem === 'apps'">
                <div class="layout-topbar-action-item p-col-4">
                  <a class="p-d-flex p-ai-center p-flex-column text-color p-ripple" v-ripple>
                    <i class="pi pi-image action indigo-bgcolor white-color"></i>
                    <span>Products</span>
                  </a>
                </div>
                <div class="layout-topbar-action-item p-col-4">
                  <a class="p-d-flex p-ai-center p-flex-column text-color p-ripple" v-ripple>
                    <i class="pi pi-file-pdf action orange-bgcolor white-color"></i>
                    <span>Reports</span>
                  </a>
                </div>
                <div class="layout-topbar-action-item p-col-4">
                  <a class="p-d-flex p-ai-center p-flex-column text-color p-ripple" v-ripple>
                    <i class="pi pi-dollar action teal-bgcolor white-color"></i>
                    <span>Balance</span>
                  </a>
                </div>
                <div class="layout-topbar-action-item p-col-4">
                  <a class="p-d-flex p-ai-center p-flex-column text-color p-ripple" v-ripple>
                    <i class="pi pi-cog action pink-bgcolor white-color"></i>
                    <span>Settings</span>
                  </a>
                </div>
                <div class="layout-topbar-action-item p-col-4">
                  <a class="p-d-flex p-ai-center p-flex-column text-color p-ripple" v-ripple>
                    <i class="pi pi-key action bluegrey-bgcolor white-color"></i>
                    <span>Credentials</span>
                  </a>
                </div>
                <div class="layout-topbar-action-item p-col-4">
                  <a class="p-d-flex p-ai-center p-jc-center p-flex-column text-color p-ripple" v-ripple>
                    <i class="pi pi-sitemap action cyan-bgcolor white-color"></i>
                    <span>Sitemap</span>
                  </a>
                </div>
              </div>
            </transition>
          </li> -->

          <li v-show="false" class="layout-topbar-item">
            <a
              class="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2 rounded-circle p-ripple"
              @click="onTopbarItemClick($event, 'profile')"
              v-ripple
            >
              <Avatar :image="$store.state?.user?.photoUrl || $auth.data.user?.picture" shape="circle" />
            </a>

            <transition name="layout-topbar-action-panel">
              <ul class="layout-topbar-action-panel p-shadow-6" v-show="activeTopbarItem === 'profile'">
                <li class="layout-topbar-action-item">
                  <a class="p-d-flex p-flex-row p-ai-center p-ripple" v-ripple>
                    <i class="pi pi-cog" :class="{ 'p-mr-2': !isRTL, 'p-ml-2': isRTL }"></i>
                    <span>Settings</span>
                  </a>
                </li>
                <li class="layout-topbar-action-item">
                  <a class="p-d-flex p-flex-row p-ai-center p-ripple" v-ripple>
                    <i class="pi pi-file-o" :class="{ 'p-mr-2': !isRTL, 'p-ml-2': isRTL }"></i>
                    <span>Terms of Usage</span>
                  </a>
                </li>
                <li class="layout-topbar-action-item">
                  <a class="p-d-flex p-flex-row p-ai-center p-ripple" v-ripple>
                    <i class="pi pi-compass" :class="{ 'p-mr-2': !isRTL, 'p-ml-2': isRTL }"></i>
                    <span>Support</span>
                  </a>
                </li>
                <li class="layout-topbar-action-item" @click="$auth.logout()">
                  <a class="p-d-flex p-flex-row p-ai-center p-ripple" v-ripple>
                    <i class="pi pi-power-off" :class="{ 'p-mr-2': !isRTL, 'p-ml-2': isRTL }"></i>
                    <span>Logout</span>
                  </a>
                </li>
              </ul>
            </transition>
          </li>

          <li class="layout-topbar-item">
            <a
              data-test="show-right-panel-button"
              class="layout-topbar-action rounded-circle p-ripple"
              @click="onRightPanelButtonClick($event)"
              v-ripple
            >
              <i
                class="pi fs-large"
                :class="{ 'pi-arrow-left': !isRightPanelVisible, 'pi-arrow-right': isRightPanelVisible }"
              ></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'
import { useAbility } from '@casl/vue'

import NotificationList from './components/notification/NotificationList.vue'

export default {
  emits: [
    'menubutton-click',
    'topbar-menubutton-click',
    'topbaritem-click',
    'rightpanel-button-click',
    'topbar-mobileactive',
    'search-click',
    'search-toggle'
  ],
  components: { NotificationList },
  setup() {
    const { can } = useAbility()
    const isRightPanelVisible = inject('isRightPanelVisible')
    return { can, isRightPanelVisible }
  },
  data() {
    return {
      searchText: '',
      items: [
        {
          label: 'UI KIT',
          items: [
            [
              {
                label: 'UI KIT 1',
                items: [
                  { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', to: '/formlayout' },
                  { label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/input' },
                  { label: 'Float Label', icon: 'pi pi-fw pi-bookmark', to: '/floatlabel' },
                  { label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/button' },
                  { label: 'File', icon: 'pi pi-fw pi-file', to: '/file' }
                ]
              }
            ],
            [
              {
                label: 'UI KIT 2',
                items: [
                  { label: 'Table', icon: 'pi pi-fw pi-table', to: '/table' },
                  { label: 'List', icon: 'pi pi-fw pi-list', to: '/list' },
                  { label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/tree' },
                  { label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/panel' },
                  { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/chart' }
                ]
              }
            ],
            [
              {
                label: 'UI KIT 3',
                items: [
                  { label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/overlay' },
                  { label: 'Media', icon: 'pi pi-fw pi-image', to: '/media' },
                  { label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/menus' },
                  { label: 'Message', icon: 'pi pi-fw pi-comment', to: '/messages' },
                  { label: 'Misc', icon: 'pi pi-fw pi-circle-off', to: '/misc' }
                ]
              }
            ]
          ]
        },
        {
          label: 'UTILITIES',
          items: [
            [
              {
                label: 'UTILITIES 1',
                items: [
                  { label: 'Display', icon: 'pi pi-fw pi-desktop', to: '/display' },
                  { label: 'Elevation', icon: 'pi pi-fw pi-external-link', to: '/elevation' }
                ]
              },
              {
                label: 'UTILITIES 2',
                items: [{ label: 'FlexBox', icon: 'pi pi-fw pi-directions', to: '/flexbox' }]
              }
            ],
            [
              {
                label: 'UTILITIES 3',
                items: [{ label: 'Icons', icon: 'pi pi-fw pi-search', to: '/icons' }]
              },
              {
                label: 'UTILITIES 4',
                items: [
                  { label: 'Text', icon: 'pi pi-fw pi-pencil', to: '/text' },
                  { label: 'Widgets', icon: 'pi pi-fw pi-star-o', to: '/widgets' }
                ]
              }
            ],
            [
              {
                label: 'UTILITIES 5',
                items: [
                  { label: 'Grid System', icon: 'pi pi-fw pi-th-large', to: '/grid' },
                  { label: 'Spacing', icon: 'pi pi-fw pi-arrow-right', to: '/spacing' },
                  { label: 'Typography', icon: 'pi pi-fw pi-align-center', to: '/typography' }
                ]
              }
            ]
          ]
        }
      ]
    }
  },
  props: {
    horizontal: {
      type: Boolean,
      default: false
    },
    topbarMenuActive: {
      type: Boolean,
      default: false
    },
    activeTopbarItem: String,
    mobileTopbarActive: Boolean,
    searchActive: Boolean
  },
  methods: {
    onSearchContainerClick(event) {
      this.$emit('search-click', event)
    },
    changeSearchActive(event) {
      this.$emit('search-toggle', event)
    },
    onMenuButtonClick(event) {
      this.$emit('menubutton-click', event)
    },
    onTopbarMenuButtonClick(event) {
      this.$emit('topbar-menubutton-click', event)
    },
    onTopbarItemClick(event, item) {
      if (item === 'search') {
        this.$emit('search-toggle', event)
      }

      this.$emit('topbaritem-click', { originalEvent: event, item: item })
    },
    onTopbarMobileButtonClick(event) {
      this.$emit('topbar-mobileactive', event)
    },
    onRightPanelButtonClick(event) {
      this.$emit('rightpanel-button-click', event)
    },
    onSearchKeydown(event) {
      if (event.keyCode === 13) {
        this.$emit('search-toggle', event)
      }
    },
    onEnter() {
      if (this.$refs.searchInput) {
        this.$refs.searchInput.$el.focus()
      }
    }
  },
  computed: {
    topbarItemsClass() {
      return [
        'topbar-items animated fadeInDown',
        {
          'topbar-items-visible': this.topbarMenuActive
        }
      ]
    },
    isRTL() {
      return this.$appState.RTL
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-topbar {
  .layout-topbar-left {
    .layout-topbar-logo {
      width: 19rem;
    }
    .layout-menu-button {
      left: 17.75rem;
    }
  }
}

.company-logo {
  margin-bottom: 0;
  color: var(--topbar-text-color);
}
.btn-reporting__container {
  gap: 0.8rem !important;
}
.btn-reporting {
  color: var(--topbar-text-color) !important;
}
.btn-reporting:hover {
  opacity: 0.7;
}
.p-button:disabled {
  color: var(--topbar-text-color) !important;
  border-width: 0.11rem !important;
  border-color: var(--topbar-text-color) !important;
}
.notif-bell {
  :deep(.p-badge) {
    display: none;
  }
}

.badge-active {
  :deep(.p-badge) {
    display: block;
  }
}
</style>
