<template>
  <Button
    v-show="false"
    data-test="toggle-button"
    class="p-button-rounded toggle-btn"
    :icon="icon"
    @click="$emit('update:modelValue', !modelValue)"
  />
  <transition name="slide">
    <div ref="rightPanel" id="right-panel" data-test="right-panel" v-show="modelValue" class="right-panel">
      <WhosOut :key="`whos-out-${costCenterId}`" :costCenterId="costCenterId" />
      <Celebrations class="p-mt-3" :key="`celebrations-${costCenterId}`" :costCenterId="costCenterId" />
    </div>
  </transition>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import WhosOut from './components/right-panel/WhosOut.vue'
import Celebrations from './components/right-panel/Celebrations.vue'

defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: { type: Boolean, default: true }
})
const store = useStore()
const costCenterId = computed(() => store.state?.selectedCostCenterId)

const icon = computed(() => (props.modelValue ? 'pi pi-chevron-right' : 'pi pi-chevron-left'))
</script>

<style lang="scss" scoped>
.right-panel {
  max-width: 19rem;
  min-width: 19rem;
  flex: 0.3;
  padding: 1rem 1rem 1rem 0;
}
.toggle-btn {
  position: fixed;
  bottom: 4.5rem;
  right: 10px;
  opacity: 0.2;
}
.toggle-btn:hover {
  opacity: 0.9;
}
.slide-enter-active {
  transition: all 50ms linear;
}
.slide-leave-active {
  transition: all 50ms linear;
}
.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
