<template>
  <div class="avatar-list">
    <div class="p-mt-1" v-if="loading">
      <Skeleton data-test="sk-title" class="list-title" width="40%" />
      <div class="list-item__container p-d-flex p-flex-column">
        <!-- Show 5 loading items -->
        <AvatarListItem
          class="p-mb-1"
          data-test="sk-avatar-list-item"
          v-for="i in [0, 1, 3, 4, 5]"
          :key="i"
          :loading="true"
        />
      </div>
    </div>
    <div v-else>
      <p v-if="title" data-test="title" class="list-title">{{ title }}</p>
      <div class="list-item__container p-d-flex p-flex-column">
        <AvatarListItem
          data-test="avatar-list-item"
          v-for="(d, i) in items"
          :key="i"
          :image="d.image"
          :title="d.title"
          :subtitle="d.subtitle"
          :isActive="d.status"
          :tooltip="d.tooltip"
          :badgeColor="d.badgeColor"
          @dblclick="handleDblClick($event, d.dblClickPayload)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import AvatarListItem from './AvatarListItem.vue'
defineProps({
  title: { type: String, required: false },
  items: { type: Array, required: false },
  loading: { type: Boolean, default: false }
})
const emit = defineEmits(['item-dblclick'])

const handleDblClick = (event, payload) => {
  event.preventDefault()
  event.stopPropagation()
  emit('item-dblclick', payload)
}
</script>

<style scoped>
.list-title {
  margin-bottom: 0.3rem;
}
.list-item__container {
  gap: 0.3em;
}
</style>
