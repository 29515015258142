<template>
  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-right" />
  <Toast position="bottom-left" group="bl" />
  <router-view v-if="$route.meta.public"></router-view>
  <AppInit v-else-if="$route.name === 'init'" />
  <App
    v-else-if="$auth.data.isAuthenticated"
    :topbarTheme="topbarTheme"
    :layoutMode="layoutMode"
    :menuTheme="menuTheme"
    :componentTheme="theme"
    @menu-theme="onMenuTheme"
    @topbar-theme="onTopbarThemeChange"
    @layout-mode-change="onLayoutChange"
  />
  <Login />
</template>

<script>
import App from './App.vue'
import AppInit from './pages/AppInit.vue'
import Login from './pages/Login.vue'

import { provide, ref, watch } from 'vue'
import { useLocalStorage } from 'vue-composable'

export default {
  setup() {
    const { storage: themeConfig } = useLocalStorage('__themeconfig', {
      layoutMode: 'light',
      menuTheme: 'light',
      theme: 'blue',
      topbarTheme: 'lightblue'
    })
    const layoutMode = ref('light')
    const menuTheme = ref('light')
    const theme = ref('blue')
    const topbarTheme = ref('lightblue')

    watch(layoutMode, (value) => (themeConfig.value.layoutMode = value))
    watch(menuTheme, (value) => (themeConfig.value.menuTheme = value))
    watch(theme, (value) => (themeConfig.value.theme = value))
    watch(topbarTheme, (value) => (themeConfig.value.topbarTheme = value))

    provide('setTheme', (value) => {
      theme.value = value
    })

    return { themeConfig, layoutMode, menuTheme, theme, topbarTheme }
  },
  methods: {
    onLayoutChange(layout) {
      this.layoutMode = layout
      this.$appState.layoutMode = layout
      this.menuTheme = layout

      const logo = document.getElementById('logo')
      if (logo) {
        logo.src = process.env.BASE_URL + 'assets/layout/images/logo-light.svg'
      }

      if (layout === 'dark') {
        this.topbarTheme = 'dark'
      } else {
        this.topbarTheme = 'blue'
      }
    },
    onTopbarThemeChange(theme) {
      this.topbarTheme = theme.name

      const themeName = theme.name
      const logo = document.getElementById('logo')

      if (logo) {
        if (
          themeName === 'white' ||
          themeName === 'yellow' ||
          themeName === 'amber' ||
          themeName === 'orange' ||
          themeName === 'lime'
        ) {
          logo.src = process.env.BASE_URL + 'assets/layout/images/logo-dark.svg'
        } else {
          logo.src = process.env.BASE_URL + 'assets/layout/images/logo-light.svg'
        }
      }
    },
    onMenuTheme(menuTheme) {
      this.menuTheme = menuTheme.name
    }
  },
  components: {
    App,
    AppInit,
    Login
  }
}
</script>

<style scoped></style>
