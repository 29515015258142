<template>
  <ul v-if="$store.state.notifications.length" class="layout-topbar-action-panel p-shadow-6">
    <li class="p-mb-3">
      <span v-if="unreads" class="p-px-3 fs-small">You have <b>{{ unreads }}</b> new notifications</span>
    </li>
    <NotificationListItem v-for="notif in $store.state.notifications" :key="notif.id" :data="notif" />
  </ul>
  <ul v-else class="layout-topbar-action-panel p-shadow-6">
    <li class="p-py-6 p-text-center" style="height: 100px;">
      <span>You dont have new notifications at the moment</span>
    </li>
  </ul>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import NotificationListItem from './NotificationListItem.vue'

const store = useStore()

const unreads = computed(() => store.getters.unreadNotifications)
</script>
