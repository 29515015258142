<template>
  <Divider class="p-m-0" style="z-index: 3" />
  <Panel
    id="whos-online-panel"
    :header="header"
    :class="{ collapse: isCollapsed }"
    :toggleable="true"
    @toggle="isCollapsed = $event.value"
  >
    <AvatarList :loading="loading" :items="whosOnline" @item-dblclick="handleOpenChannel" />
  </Panel>
</template>

<script setup>
import { computed, ref, onUnmounted } from 'vue'
import { useInterval } from 'vue-composable'
import { useToast } from 'primevue/usetoast'

import AvatarList from './right-panel/AvatarList.vue'
import useUsers from '../hooks/useUsers'
import { parseWhosOnlineList } from '../utils/utilities'

const toast = useToast()
const { fetchStaffsStatus } = useUsers()

const data = ref(null)
const loading = ref(true)
const isCollapsed = ref(false)
const REFRESH_INTERVAL = 15 * 60 * 1000 // 15 minutes
const MAX_ONLINE_MEMBER_INDICATOR = 999

const whosOnline = computed(() => data.value?.map((el) => parseWhosOnlineList(el)))
const header = computed(() => {
  const title = 'ACTIVE'
  if (loading.value) {
    return title
  }
  const numOnline = whosOnline.value?.filter((el) => el.isOnline)?.length || 0
  return `${title} (${numOnline < MAX_ONLINE_MEMBER_INDICATOR + 1 ? numOnline : `${MAX_ONLINE_MEMBER_INDICATOR}+`})`
})

const handleOpenChannel = (payload) => {
  /* double clicking avatart list item opens user primary slack channel */
  if (payload) {
    window.open(payload, '_blank', 'popup,width=450,height=700')
  } else {
    toast.removeGroup('bl')
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'No available channel assigned.',
      life: 3000,
      group: 'bl'
    })
  }
}

const refreshWhosOnline = async () => {
  data.value = await fetchStaffsStatus()
}

refreshWhosOnline().then(() => {
  loading.value = false
  const remove = useInterval(refreshWhosOnline, REFRESH_INTERVAL)
  onUnmounted(() => remove())
})
</script>

<style lang="scss" scoped>
#whos-online-panel {
  overflow-y: hidden;
  box-shadow: none;
  border-radius: 0;
  max-height: 260px;
  min-height: 260px;

  :deep(.p-panel-header) {
    padding: 0.3rem 1rem;
    border-radius: 0;
    z-index: 2;
    position: sticky;
    top: 0;
  }
  :deep(.p-panel-title) {
    font-size: 0.857rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #657380;
  }
  :deep(.avatar-list-item) {
    margin-left: unset;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    .title {
      font-size: 1rem;
      color: #515c66;
    }
  }
}

.collapse {
  min-height: 34px !important;
}

#whos-online-panel:hover {
  overflow-y: overlay;
}

:deep(.avatar-list) {
  .list-title {
    display: none;
  }
}
</style>
