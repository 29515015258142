import { watch } from 'vue'
import { useCookie } from '@vue-composable/cookie'

import ability from '../plugins/ability'
import { useAuth0 } from '../plugins/auth'
import { parsePermissionString } from '../utils/utilities'

export const authGuard = (to, from) => {
  const auth = useAuth0()
  const { setCookie } = useCookie('__redirect__')

  window.scrollTo(0, 0)
  const guardAction = async () => {
    const isAuthenticated = await auth.isAuthenticated()

    // check if callback url contains error
    const urlSearchParams = new URLSearchParams(window.location.search)
    const error = urlSearchParams.get('error')
    if (error && error === 'unauthorized') {
      return { name: 'access-denied' }
    }

    if (isAuthenticated === false) {
      console.log(to)
      if (to.name !== 'init') {
        setCookie(JSON.stringify(to))
      }
      await auth.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
      return
    }

    // check permissions
    if (to.meta?.permissions) {
      const permmissions = to.meta.permissions.map((p) => {
        const { action, subject } = parsePermissionString(p)
        return ability.can(action, subject)
      })
      if (!permmissions.every((el) => !!el)) {
        return { name: 'access-denied' }
      }
    }
  }

  return new Promise((resolve, reject) => {
    if (auth.data.isLoading === false) {
      guardAction()
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          console.log(err)
          reject(err)
        })
    }

    watch(
      () => auth.data.isLoading,
      (isLoading) => {
        if (isLoading === false) {
          guardAction()
            .then((res) => {
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
        }
      }
    )
  })
}
