import { computed, reactive, ref } from 'vue'
import createAuth0Client from '@auth0/auth0-spa-js'
import jwtDecode from 'jwt-decode'

import { AUTH0_DEFAULT_DATA_STATE, USER_TYPES } from '../definitions'

let instance

const defaultRedirectCallback = () => {
  window.history.replaceState({}, document.title, window.location.pathname)
  window.location.search = ''
}

export const useAuth0 = (onRedirectCallback = defaultRedirectCallback) => {
  if (instance) return instance
  instance = auth0Instance(onRedirectCallback)
  return instance
}

const auth0Instance = (onRedirectCallback) => {
  const client = ref(null)
  const data = reactive({ ...AUTH0_DEFAULT_DATA_STATE })

  // created lifecycle hook
  ;(async () => {
    client.value = await createAuth0Client({
      domain: process.env.VUE_APP_AUTH0_DOMAIN,
      client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
      redirect_uri: process.env.VUE_APP_AUTH0_REDIRECT_URI,
      cacheLocation: process.env.VUE_APP_AUTH0_CACHE_LOCATION,
      useRefreshTokens: process.env.VUE_APP_AUTH0_USE_REFRESH_TOKENS === 'true',
      audience: process.env.VUE_APP_AUTH0_AUDIENCE
    })

    try {
      if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
        const { appState } = await client.value.handleRedirectCallback()
        onRedirectCallback(appState)
      }
      await initData()
    } catch (error) {
      data.error = error
    } finally {
      data.isLoading = false
    }
  })()

  const handleRedirectCallback = async () => {
    data.isLoading = true
    try {
      await client.value.handleRedirectCallback()
    } catch (error) {
      data.error = error
    } finally {
      data.isLoading = false
    }
  }

  const initData = async () => {
    data.user = await client.value.getUser()
    data.isAuthenticated = await client.value.isAuthenticated()
    const token = await client.value?.getTokenSilently()
    const { permissions } = jwtDecode(token)
    data.permissions = permissions
  }

  const getTokenSilently = async (options) => await client.value.getTokenSilently(options)

  const isAuthenticated = async () => await client.value.isAuthenticated()

  const loginWithRedirect = async (options) =>
    await client.value.loginWithRedirect({
      access_type: 'offline',
      approval_prompt: 'force',
      ...options
    })

  const loginWithPopup = async (options) => {
    try {
      data.isLoading = true
      await client.value.loginWithPopup()
      await initData()
    } finally {
      data.isLoading = false
    }
  }

  const userType = computed(() => {
    if (!data.user?.email) {
      return null
    }
    // eslint-disable-next-line no-unused-vars
    const [_, domain] = data.user.email.split('@')
    return ['go2.io', 'go2impact.com'].includes(domain) ? USER_TYPES.INTERNAL : USER_TYPES.EXTERNAL
  })

  const logout = async (options) => {
    try {
      await client.value.logout({ returnTo: process.env.VUE_APP_AUTH0_LOGOUT_RETURN_TO_URL, ...options })
      Object.assign(data, AUTH0_DEFAULT_DATA_STATE)
    } finally {
      data.isLoading = false
    }
  }

  return {
    data,
    getTokenSilently,
    initData,
    isAuthenticated,
    handleRedirectCallback,
    loginWithPopup,
    loginWithRedirect,
    logout,
    userType
  }
}

export const Auth0Plugin = {
  install: async (app, options) => {
    const { onRedirectCallback } = options
    app.config.globalProperties.$auth = useAuth0(onRedirectCallback)
  }
}
