import useHttp from './useHttp'

export default () => {
  const http = useHttp()

  const checkSlackMembership = async channel => ({ data: { is_member: true } })
  // await http.exec({ method: 'GET', url: '/slack/check-membership/', params: { channel } })

  const setSlackToken = async code =>
    await http.exec({ method: 'PATCH', url: '/users/set-slack-token/', data: { code } })

  return { ...http, checkSlackMembership, setSlackToken }
}
