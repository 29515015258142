const types = {
  // mutations
  INIT_COST_CENTERS: 'INIT_COST_CENTERS',
  ADD_EMBED_MENU: 'ADD_EMBED_MENU',
  UPDATE_EMBED_MENU: 'UPDATE_EMBED_MENU',
  DELETE_EMBED_MENU: 'DELETE_EMBED_MENU',
  SELECT_COST_CENTER: 'SELECT_COST_CENTER',
  INIT_USER_DATA: 'INIT_USER_DATA',
  INITIALIZED: 'INITIALIZED',
  SET_INTERCOM_STATE: 'SET_INTERCOM_STATE',
  UPDATE_NOTIFICATIONS: 'UPDATE_NOTIFICATION',

  // actions
  NOTIFICATIONS_FETCH: 'NOTIFICATIONS_FETCH'
}

export { types }
