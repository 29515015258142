import { createApp, h, reactive } from 'vue'

import PrimeVue from 'primevue/config'
import AppWrapper from './AppWrapper.vue'
import AutoComplete from 'primevue/autocomplete'
import Avatar from 'primevue/avatar'
import Badge from 'primevue/badge'
import BadgeDirective from 'primevue/badgedirective'
import Button from 'primevue/button'
import Calendar from 'primevue/calendar'
import Card from 'primevue/card'
import Checkbox from 'primevue/checkbox'
import Chip from 'primevue/chip'
import Chips from 'primevue/chips'
import Column from 'primevue/column'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmPopup from 'primevue/confirmpopup'
import ConfirmationService from 'primevue/confirmationservice'
import DataTable from 'primevue/datatable'
import DataView from 'primevue/dataview'
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import Dialog from 'primevue/dialog'
import Divider from 'primevue/divider'
import Dropdown from 'primevue/dropdown'
import Editor from 'primevue/editor'
import Fieldset from 'primevue/fieldset'
import InputNumber from 'primevue/inputnumber'
import InputSwitch from 'primevue/inputswitch'
import InputText from 'primevue/inputtext'
import MegaMenu from 'primevue/megamenu'
import Message from 'primevue/message'
import MultiSelect from 'primevue/multiselect'
import OverlayPanel from 'primevue/overlaypanel'
import Paginator from 'primevue/paginator'
import Panel from 'primevue/panel'
import RadioButton from 'primevue/radiobutton'
import Rating from 'primevue/rating'
import Ripple from 'primevue/ripple'
import ScrollPanel from 'primevue/scrollpanel'
import Slider from 'primevue/slider'
import Sidebar from 'primevue/sidebar'
import Skeleton from 'primevue/skeleton'
import Steps from 'primevue/steps'
import Textarea from 'primevue/textarea'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import Tag from 'primevue/tag'
import Tooltip from 'primevue/tooltip'

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import CodeHighlight from './AppCodeHighlight'
import VueGtag from 'vue-gtag'

import LabeledField from '@/components/custom/LabeledField.vue'

import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import 'prismjs/themes/prism-coy.css'
import 'vue-loading-overlay/dist/vue-loading.css'

import ability from './plugins/ability'
import router from './router'
import { store } from './store/index'
import { abilitiesPlugin } from '@casl/vue'
import { Auth0Plugin } from './plugins/auth'

const app = createApp({
  render() {
    return h(AppWrapper)
  }
})

app.use(Auth0Plugin, {
  onRedirectCallback: (appState) =>
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
})

app.use(PrimeVue, { ripple: true })
app.use(ConfirmationService)
app.use(ToastService)
app.use(store)
app.use(router)
app.use(abilitiesPlugin, ability)

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [process.env.VUE_APP_SENTRY_TRACING_ORIGIN, /^\//]
      })
    ],
    tracesSampleRate: +process.env.VUE_APP_SENTRY_TRACE_SAMPLE_RATE,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT
  })

  app.use(
    VueGtag,
    {
      appName: 'Portal PROD',
      pageTrackerScreenviewEnabled: true,
      config: { id: 'GTM-W92454B' }
    },
    router
  )
}

if (process.env.NODE_ENV !== 'production') {
  app.config.performance = true
}

app.config.globalProperties.$appState = reactive({
  inputStyle: 'filled',
  RTL: false,
  isNewThemeLoaded: false,
  layoutMode: 'light'
})

app.directive('tooltip', Tooltip)
app.directive('badge', BadgeDirective)
app.directive('ripple', Ripple)
app.directive('code', CodeHighlight)

app.component('AutoComplete', AutoComplete)
app.component('Avatar', Avatar)
app.component('Badge', Badge)
app.component('Button', Button)
app.component('Calendar', Calendar)
app.component('Card', Card)
app.component('Checkbox', Checkbox)
app.component('Chip', Chip)
app.component('Chips', Chips)
app.component('Column', Column)
app.component('ConfirmDialog', ConfirmDialog)
app.component('ConfirmPopup', ConfirmPopup)
app.component('DataTable', DataTable)
app.component('DataView', DataView)
app.component('DataViewLayoutOptions', DataViewLayoutOptions)
app.component('Dialog', Dialog)
app.component('Divider', Divider)
app.component('Dropdown', Dropdown)
app.component('Fieldset', Fieldset)
app.component('InputNumber', InputNumber)
app.component('InputSwitch', InputSwitch)
app.component('InputText', InputText)
app.component('MegaMenu', MegaMenu)
app.component('Message', Message)
app.component('MultiSelect', MultiSelect)
app.component('OverlayPanel', OverlayPanel)
app.component('Paginator', Paginator)
app.component('Panel', Panel)
app.component('RadioButton', RadioButton)
app.component('Rating', Rating)
app.component('ScrollPanel', ScrollPanel)
app.component('Slider', Slider)
app.component('Sidebar', Sidebar)
app.component('Skeleton', Skeleton)
app.component('Steps', Steps)
app.component('Tag', Tag)
app.component('Textarea', Textarea)
app.component('Toast', Toast)
app.component('Editor', Editor)

app.component('LabeledField', LabeledField)

app.mount('#app')

if (window.Cypress) {
  // only available during E2E tests
  window.vuex = store
}
