<template>
  <div class="footer layout-footer p-shadow-2">
    <div class="p-d-flex p-ai-center p-jc-around">
      <Button class="p-button-text" @click="handleFaqButtonClick">FAQ's</Button>
      <Button class="p-button-text" @click="handleCsButtonClick">Customer Support</Button>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { useAuth0 } from './plugins/auth'

const auth = useAuth0()
const router = useRouter()
const store = useStore()

const intercomBooted = computed(() => store.state.intercomBooted)

const intercomTrackEvent = (type) => {
  if (intercomBooted.value) {
    window.Intercom('trackEvent', type, { user: auth.data?.user?.email })
  }
}

const handleFaqButtonClick = () => {
  intercomTrackEvent('clicked-faq')
  window.open('https://support.go2.io/', '_blank')
}

const handleCsButtonClick = () => {
  intercomTrackEvent('clicked-customer-support')
  router.push({ name: 'speakup' })
}
</script>

<style scoped>
.footer {
  padding: 0.8rem 12.5rem !important;
}
</style>
