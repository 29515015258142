import { ref } from 'vue'
import { format } from 'date-fns'

import useHttp from './useHttp'
import { shiftDays } from '../utils/utilities'
import { ISO_DATE_FORMAT } from '../definitions'

/**
 *
 * @typedef {Object} loadMoreParams
 * @property {string} costCenterId
 * @property {Date} [startDate]
 * @property {number} [numDays=7]
 */

export default (startDate) => {
  const http = useHttp()

  const allowLoadMore = ref(true)
  const celebrations = ref(null)
  const fetchStart = ref(startDate || new Date())
  const whosOut = ref(null)
  const schedules = ref([])

  /**
   *
   * @param {string} costCenterId
   * @param {Date} [startDate]
   * @param {number} [numDays=30]
   * @returns
   */
  const fetchCelebrations = async (costCenterId, startDate, numDays = 30) => {
    const start = format(startDate || fetchStart.value, ISO_DATE_FORMAT)
    const params = { cost_center_id: costCenterId, start, num_days: numDays }
    const resp = await http.exec({ method: 'GET', url: '/bhr/celebrations/', params })
    if (!http.error.value && resp.data) {
      celebrations.value = resp.data
      fetchStart.value = shiftDays(fetchStart.value, numDays + 1)
    }
  }

  /**
   *
   * @param {string} costCenterId
   * @param {Date} [startDate]
   * @param {number} [numDays=7]
   * @returns
   */
  const fetchWhosOut = async (costCenterId, startDate, numDays = 7) => {
    if (!allowLoadMore.value) return
    // use fetchStart if startDate is not provided
    const start = format(startDate || fetchStart.value, ISO_DATE_FORMAT)
    const params = { cost_center_id: costCenterId, start, num_days: numDays }
    const resp = await http.exec({ method: 'GET', url: '/bhr/whos-out/', params })
    if (!http.error.value && resp.data) {
      whosOut.value = resp.data
      fetchStart.value = shiftDays(fetchStart.value, numDays + 1)
    }
  }

  /**
   *
   * @param {function} fn
   * @param {loadMoreParams} params
   * @param {boolean} recursive
   * @returns
   */
  const loadMore = async (fn, params, recursive) => {
    if (!allowLoadMore.value) return
    const { costCenterId, startDate, numDays } = params
    await fn(costCenterId, startDate || fetchStart.value, numDays)
    if (!http.error.value) {
      if (recursive && http.data.value.meta.count === 0) {
        await loadMore(fn, { ...params, start: fetchStart.value }, recursive)
      }
    }
  }

  const fetchStaffSchedules = async (params) => {
    const resp = await http.exec({ method: 'GET', url: '/bhr/schedules/', params })
    if (!http.error.value && resp.data) {
      schedules.value = resp.data
    }
  }

  return {
    ...http,
    allowLoadMore,
    celebrations,
    fetchCelebrations,
    fetchStaffSchedules,
    fetchStart,
    fetchWhosOut,
    loadMore,
    schedules,
    whosOut
  }
}
