import { ref, watchEffect } from 'vue'
import { useStore } from 'vuex'

import { types } from '../store/mutation-types'
import useHttp from './useHttp'
import { useAuth0 } from '../plugins/auth'
import { USER_TYPES } from '../definitions'

export default () => {
  /* Setup initial user data */
  const auth = useAuth0()
  const store = useStore()
  const http = useHttp()

  const slackConfigured = ref('loading')
  const initialized = ref(false)

  // eslint-disable-next-line no-unused-vars
  const bootIntercom = () => {
    if (window.Intercom) {
      window.Intercom('boot', {
        app_id: process.env.VUE_APP_INTERCOM_APP_ID,
        email: auth.data?.user?.email,
        name: auth.data?.user?.name,
        user_id: auth.data?.user?.employeeId
      })
      store.commit(types.SET_INTERCOM_STATE, true)
      window.Intercom('hide')
    }
  }

  watchEffect(async () => {
    if (auth.data.isAuthenticated) {
      if (auth.userType.value === 'internal') {
        const resp = await http.exec({ method: 'GET', url: '/users/init/employees/' })
        if (!http.error.value) {
          const cc = resp.data?.cost_centers
          if (Object.keys(cc).length) {
            const costCenters = Object.keys(cc).map((name) => {
              // eslint-disable-next-line camelcase
              const { kpis, cost_center_id, menus, company_name, timezone, holidays } = cc[name]
              return { name, company_name, kpis, costCenterId: cost_center_id, menus, timezone, holidays }
            })
            store.commit(types.INIT_COST_CENTERS, costCenters)
          }
          slackConfigured.value = resp.data?.slack_configured
          auth.data.user.employeeId = resp.data?.employee_id
          store.commit(types.INIT_USER_DATA, {
            employeeId: resp.data?.employee_id,
            photoUrl: resp.data?.photo_url,
            isTdExempted: resp.data?.is_td_exempted,
            roles: resp.data?.roles,
            type: USER_TYPES.INTERNAL
          })
          store.commit(types.INITIALIZED)
        }
      } else {
        const resp = await http.exec('/users/init/customers/')
        if (!http.error.value && resp?.data) {
          const costCenters = resp.data.map((cc) => {
            // eslint-disable-next-line camelcase
            const { id, cost_center_name, company_name, holidays, kpis, pages, timezone } = cc
            return {
              costCenterId: id,
              name: cost_center_name,
              company_name,
              holidays,
              kpis: kpis.filter((kpi) => !!kpi),
              menus: pages
                .filter((page) => !!page)
                .map((page) => ({ ...page, submenus: page?.submenus?.filter((submenu) => !!submenu) })),
              timezone
            }
          })
          store.commit(types.INIT_USER_DATA, { type: USER_TYPES.EXTERNAL })
          store.commit(types.INIT_COST_CENTERS, costCenters)
          store.commit(types.INITIALIZED)
        }
      }
      // if (process.env.NODE_ENV === 'production') {
      //   const { email, given_name: firstName, family_name: familyName } = auth.data.user
      //   window.hj('identify', email, { name: `${firstName} ${familyName}` })
      // }
      initialized.value = true
      // bootIntercom()
    }
  })

  return { ...http, initialized, slackConfigured }
}
