<template>
  <div />
</template>

<script>
import { ref } from 'vue'

import useSlack from '../hooks/useSlack'
import { useAuth0 } from '../plugins/auth'
import { useRouter } from 'vue-router'
// import { USER_TYPES } from '../definitions'

export default {
  setup() {
    const auth = useAuth0()
    const router = useRouter()
    const { setSlackToken } = useSlack()

    const isLoading = ref(true)
    ;(async () => {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const code = urlSearchParams.get('code')
      const state = urlSearchParams.get('state')
      // temp fix
      if (code && (!state || !state?.length)) {
        await setSlackToken(code)
        location.replace(process.env.VUE_APP_SLACK_REDIRECT_URI)
        return
      }
      isLoading.value = false

      const isAuthenticated = await auth.isAuthenticated()
      if (isAuthenticated) {
        if (!auth.data?.user) {
          isLoading.value = true
          await auth.initData()
          isLoading.value = false
        }

        router.replace({ name: 'home' })
      } else {
        await auth.loginWithRedirect()
      }
    })()

    return { isLoading }
  }
}
</script>

<style scoped>
</style>
