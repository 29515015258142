import { watch } from 'vue'
import { defineAbility } from '@casl/ability'

import { useAuth0 } from './auth'
import { parsePermissionString } from '../utils/utilities'

const auth = useAuth0()
const ability = defineAbility((can, cannot) => {})

const stop = watch(
  () => auth.data.permissions,
  (value) => {
    const rulesFromToken = value.map((permission) => parsePermissionString(permission))
    const rules = [...ability.rules, ...rulesFromToken]
    ability.update(rules)
    stop()
  }
)

export default ability
