<template>
  <ul class="menu-bar p-d-flex p-flex-column p-ai-center p-py-3">
    <span v-if="props.isLoading">
      <li class="p-py-2" v-for="index in 20" :key="index"><Skeleton shape="circle" size="40px" /></li>
    </span>
    <span v-else>
      <li class="p-py-2" v-for="(cc, index) in costCenters" :key="index">
        <Button
          class="p-button-rounded p-button-sm menu-item"
          v-tooltip.right="cc.name"
          :label="getLabel(cc.name)"
          :disabled="cc.costCenterId === $store.state.selectedCostCenterId"
          @click="handleSelectCostCenter(cc.costCenterId)"
        />
      </li>
    </span>
  </ul>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import { types } from './store/mutation-types'

const props = defineProps({
  isLoading: { type: Boolean, default: true }
})

const store = useStore()

const costCenters = computed(() => store.state.costCenters)

const handleSelectCostCenter = (id) => store.commit(types.SELECT_COST_CENTER, id)
const getLabel = (ccName) =>
  ccName
    ?.split('|')
    ?.map((el) => el.replace(/\W/g, '').trim().slice(0, 1))
    .join('')
</script>

<style scoped>
.menu-bar {
  height: 100%;
  background-color: var(--surface-c);
  overflow-y: hidden;
}
.menu-bar:hover {
  overflow-y: overlay;
}
.menu-item {
  max-width: 43.7px !important;
  width: 40px !important;
  min-width: 0px !important;
  max-height: 43.7px !important;
  height: 40px !important;
  min-height: 40px !important;
}
</style>
