import { camelCase, capitalize, isArray, isObject, transform } from 'lodash-es'
import { addDays } from 'date-fns'
import { zonedTimeToUtc, format as tzFormat, utcToZonedTime } from 'date-fns-tz'
import { SYSTEM_TIME_ZONE } from '../definitions'

const DATE_FORMAT = 'LLL d'

// https://stackoverflow.com/questions/59769649/recursively-convert-an-object-fields-from-snake-case-to-camelcase
export const camelize = (obj) =>
  transform(obj, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key)
    acc[camelKey] = isObject(value) ? camelize(value) : value
  })

export const delay = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })

export const prettifyDuration = (seconds) => {
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60
  return `${hours} Hour${hours > 1 ? 's' : ''} and ${mins} Minute${mins > 1 ? 's' : ''}`
}

/**
 *
 * @param {string} input
 * @returns string
 */
export const tagStripper = (input) => (!input ? '' : input?.replace(/<.+?>/g, ''))

export const parsePage = (data) => {
  const getQueryString = (url) => `?${url.split('?')['1']}`
  let { next, previous } = data
  if (next) {
    const params = new URLSearchParams(getQueryString(next))
    next = params.get('page')
  }
  if (previous) {
    const params = new URLSearchParams(getQueryString(previous))
    previous = params.get('page') || '1'
  }
  let current
  if (next) {
    next = parseInt(next)
    current = next - 1
  } else if (previous) {
    previous = parseInt(previous)
    current = previous + 1
  } else {
    current = 1
  }

  return { ...data, current, next, previous }
}

export const parseFilters = (rawFilter) => {
  const filters = Object.keys(rawFilter)
    .map((el) => rawFilter[el])
    .filter((el) => el.value !== null)
  const queryFilter = {}
  filters.forEach((el) => (queryFilter[el.query] = el.value))
  return queryFilter
}

export const formatNestedMenu = (menu) => {
  const value = { label: menu.title, icon: menu.icon }
  value.to = {
    name: 'menu-content',
    params: { menuId: menu.id }
  }

  const items = menu?.submenus
    ?.sort(({ display_priority: dpa }, { display_priority: dpb }) => dpa - dpb)
    .map((submenu) => {
      const value = { label: submenu.title, icon: submenu.icon }
      value.to = {
        name: 'menu-content',
        params: { menuId: submenu.id }
      }
      return value
    })
  if (items?.length) {
    value.items = items
  }

  return value
}

export const handleHttpError = (error, toast, message, life = 5000) => {
  /* Format and displays error message with a toast message */
  if (!error) return
  let detail
  if (error?.response?.data?.detail) {
    detail = error.response.data?.detail
  } else if (error?.message) {
    detail = error.message
  } else {
    detail = error
  }
  if (message) {
    detail = `${detail}. ${message}`
  }
  toast.add({ severity: 'error', summary: 'Error', detail, life })
}

export const formatDateTime = (dateString) => {
  const ymd = dateString.split('T')[0]
  const date = new Date(dateString)
  return `${ymd} ${date.toLocaleTimeString()}`
}

export const formatDateRangeFilter = (d1, d2) => {
  const _formatDate = (year, month, date) => {
    const m = `0${month}`.slice(-2)
    const d = `0${date}`.slice(-2)
    return new Date(`${year}-${m}-${d}T00:00:00.000Z`)
  }

  const start = _formatDate(d1.getFullYear(), d1.getMonth() + 1, d1.getDate())
  let end

  if (d2 instanceof Date) {
    end = _formatDate(d2.getFullYear(), d2.getMonth() + 1, d2.getDate() + 1)
  } else {
    end = _formatDate(d1.getFullYear(), d1.getMonth() + 1, d1.getDate() + 1)
  }
  return [start, end]
}

export const shiftDaysOld = (date, days) => {
  const d = new Date(date)
  d.setDate(d.getDate() + days)
  return d.toISOString().substr(0, 10)
}

export const shiftDays = (date, days) => addDays(date, days)

export const parseWhosOutListItem = (item) => {
  const SHORT_MONTH_FORMAT = 'LLL'

  const title = item.name
  const image = item.photo_url
  const [d1, d2] = item.timeoff_range.split(',')
  if (d1 === d2) {
    return { title, image }
  }

  const start = utcToZonedTime(d1, SYSTEM_TIME_ZONE)
  const end = utcToZonedTime(d2, SYSTEM_TIME_ZONE)
  const startMonth = tzFormat(start, SHORT_MONTH_FORMAT)
  const startDate = start.getDate()
  const endMonth = tzFormat(end, SHORT_MONTH_FORMAT)
  const endDate = end.getDate()
  const startMd = `${startMonth} ${startDate}`
  let subtitle
  if (startMonth === endMonth) {
    subtitle = `${startMd} - ${endDate}`
  } else {
    const endMd = `${endMonth} ${endDate}`
    subtitle = `${startMd} - ${endMd}`
  }
  return { title, subtitle, image }
}

export const parseWhosOutList = (wo) => {
  const date = utcToZonedTime(wo.timeoff_date, SYSTEM_TIME_ZONE)
  const items = wo.staffs.map((el) => parseWhosOutListItem(el))
  const title = `${tzFormat(date, DATE_FORMAT)} (${items.length})`
  return { title, items }
}

export const toOrdinalSuffix = (num) => {
  // https://www.w3resource.com/javascript-exercises/fundamental/javascript-fundamental-exercise-122.php
  const int = parseInt(num)
  const digits = [int % 10, int % 100]
  const ordinals = ['st', 'nd', 'rd', 'th']
  const oPattern = [1, 2, 3, 4]
  const tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19]
  return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
    ? int + ordinals[digits[0] - 1]
    : int + ordinals[3]
}

export const parseCelebrationsListItem = (item) => {
  const title = `${item.first_name} ${item.last_name}`
  const { date } = item
  // const md = `${date.toLocaleString('en', { month: 'short' })} ${date.getDate()}`
  const md = tzFormat(date, DATE_FORMAT)

  let subtitle = `${md} - `
  if (item.type === 'birthday') {
    subtitle = `${subtitle} Happy Birthday`
    if (item?.celebrant) {
      subtitle = `${subtitle} ${item.preferred_name}!`
    }
  } else {
    const annivs = new Date().getFullYear() - date.getFullYear()
    if (item?.celebrant) {
      subtitle = `${subtitle} Happy ${toOrdinalSuffix(annivs)} Anniversary ${item.preferred_name}!`
    } else {
      subtitle = `${subtitle} ${toOrdinalSuffix(annivs)} Anniversary`
    }
  }

  const image = item.photo_url
  return { title, subtitle, image }
}

export const parsePermissionString = (permission) => {
  const index = permission.lastIndexOf(':')
  const subject = permission.slice(0, index)
  const action = permission.slice(index + 1)
  return { action, subject }
}

/**
 * Accepts user status data and returns an object
 * compatible with AvatartList API
 * @param {Object} item - Staff status data
 * @param {string} item.display_name - Staff's name
 * @param {string} item.photo_url - Staff's photo
 * @param {string} item.status_color - Staff's status color code: green | yellow | red | grey
 * @param {string} item.status - Staff's status: td_task | on_break | potential_issue | offline
 * @param {string|null} item.top_priority - Staff's top priority recorded in SOD report
 * @param {string|null} item.channel - Slack channel url
 * @returns
 */
export const parseWhosOnlineList = (item) => {
  const title = `${item.display_name}`
  const image = item.photo_url
  const badgeColor = item.status_color
  const isOnline = badgeColor === 'green'
  const subtitle = item.status?.length > 20 ? item.status.slice(null, 20) + '...' : item.status
  const tooltip = tagStripper(item.top_priority)

  return { title, subtitle, image, tooltip, badgeColor, isOnline, dblClickPayload: item.channel }
}

export const parseScheduleCellDisplay = (sched = {}, clientTimeZone = 'EST5EDT', clientHolidays = []) => {
  const TIME_PATTERN = 'hh:mm a'
  let value
  const styles = []

  if (!sched.start_time) {
    value = null
  } else if (sched.start_time === '00:01:00') {
    value = 'FLEXI'
  } else {
    const dt = `${sched.date} ${sched.start_time}`
    // Convert EST to UTC
    const utcDate = zonedTimeToUtc(dt, 'EST5EDT')
    value = tzFormat(utcToZonedTime(utcDate, clientTimeZone), TIME_PATTERN)
  }

  const { date, hours, timeoff } = sched
  if (parseInt(timeoff) > 0) {
    styles.push('timeoff')
    value = 'OUT'
  } else if (clientHolidays.includes(date)) {
    styles.push('holiday')
  } else if (!hours) {
    styles.push('rest-day')
  }

  return { date, styles, value }
}

/**
 *
 * @param {string} selector - Query selector
 * @returns {Promise<HTMLElement>}
 */
export const waitForElm = (selector) => {
  /* https://stackoverflow.com/questions/5525071/how-to-wait-until-an-element-exists */
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector))
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector))
        observer.disconnect()
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true
    })
  })
}

/**
 * @param {string} text
 * @returns {string}
 */
export const capsEachWord = (text) => {
  return text
    .split(' ')
    .map((el) => capitalize(el))
    .join(' ')
}
