<template>
  <Card ref="card" class="right-panel-card" style="height: relative">
    <template #title>
      <slot name="title">{{ title }}</slot>
    </template>
    <template #content>
      <slot name="content">
        <div data-test="sk-avatar-lists" v-if="loading">
          <AvatarList :loading="true" />
        </div>
        <div data-test="avatar-lists" v-else>
          <AvatarList
            data-test="avatar-list"
            class="p-mb-3"
            v-for="(d, i) in data"
            :key="i"
            :loading="loading"
            :title="d.title"
            :items="d.items"
          />
        </div>
      </slot>
      <slot name="empty">
        <div data-test="no-data" v-if="!data?.length" class="p-d-flex p-ai-center p-jc-center p-my-6">
          {{ emptyMessage }}
        </div>
      </slot>
    </template>
    <template #footer>
      <slot class="p-mb-2" name="footer">
        <Button
          data-test="load-more-button"
          class="p-button-text p-button-sm"
          label="Load more"
          style="width: 100%"
          :disabled="!buttonEnabled"
          :loading="buttonLoading || loading"
          @click="$emit('load-more')"
        />
      </slot>
    </template>
  </Card>
</template>

<script setup>
import { ref, watchEffect } from 'vue'

import AvatarList from './AvatarList.vue'
defineEmits(['load-more'])
const props = defineProps({
  title: { type: String, required: false },
  loading: { type: Boolean, default: true },
  data: { type: Array, required: false },
  buttonEnabled: { type: Boolean, default: true },
  buttonLoading: { type: Boolean, default: false },
  emptyMessage: { type: String, default: 'No data available' }
})

const card = ref(null)

watchEffect(() => {
  if (card.value?.$el && props.loading) {
    card.value.$el.scrollTo({ top: 0, behavior: 'smooth' })
  }
})

defineExpose({ card })
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--surface-d);
  border-radius: 5px;
  min-height: 60px;
}

.right-panel-card {
  overflow-y: hidden;

  :deep(.p-card-body) {
    position: relative;
    padding: 0;
  }

  :deep(.p-card-title) {
    position: sticky;
    top: 0px;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
    padding: 0.8rem;
    font-size: 1.05rem;
    z-index: 2;
  }

  :deep(.p-card-content) {
    padding: 0.1rem 0.8rem 0px 0.8rem;
  }

  :deep(.p-card-footer) {
    padding-top: 0px;
  }
}
.right-panel-card:hover {
  overflow-y: overlay;
}
</style>
