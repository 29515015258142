<template>
  <RightPanelCard
    id="celebrations-panel"
    emptyMessage="No celebrations to display"
    :data="data[0].items?.length ? data : null"
    :loading="initialLoading || !costCenterId"
    :buttonEnabled="allowLoadMore"
    :buttonLoading="loading"
    @load-more="handleLoadMore"
  >
    <template #title>
      <div class="p-d-flex p-ai-center p-jc-between">
        <div data-test="title">Celebrations</div>
        <i data-test="icon" class="pi pi-calendar" style="font-size: 1.5rem" />
      </div>
    </template>
  </RightPanelCard>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { utcToZonedTime } from 'date-fns-tz'

import RightPanelCard from './RightPanelCard.vue'
import useBhr from '../../hooks/useBhr'
import { SYSTEM_TIME_ZONE } from '../../definitions'
import { parseCelebrationsListItem } from '../../utils/utilities'

const props = defineProps({ costCenterId: { type: String } })

const store = useStore()
const { allowLoadMore, fetchStart, error: bhrError, loading, loadMore, fetchCelebrations, celebrations } = useBhr()

const data = ref([{ title: null, items: [] }])
const initialLoading = ref(true)
const date = new Date()

const employeeId = computed(() => store.state?.user?.employeeId)

const handleLoadMore = async () => await loadMore(fetchCelebrations, { costCenterId: props.costCenterId }, true)

watch(celebrations, (value) => {
  if (!bhrError.value && celebrations.value?.data?.length) {
    const items = celebrations.value.data.map((el) => {
      const celebDate = utcToZonedTime(el.date, SYSTEM_TIME_ZONE)
      if (
        el.employee_id === employeeId.value &&
        celebDate.getMonth() === date.getMonth() &&
        celebDate.getDate() === date.getDate()
      ) {
        el.celebrant = true
      }
      el.date = celebDate
      return parseCelebrationsListItem(el)
    })
    data.value[0].items.push(...items)
  }
})

watch(fetchStart, (value) => {
  const dt = new Date(value)
  if (dt.getFullYear() > date.getFullYear() && dt.getMonth() >= date.getMonth()) {
    allowLoadMore.value = false
  }
})

// created
;(async () => {
  await fetchCelebrations(props.costCenterId)
  initialLoading.value = false
})()
</script>

<style lang="scss" scoped>
#celebrations-panel {
  max-height: 23.7rem !important;
  :deep(.avatar-list) {
    .list-title {
      display: none;
    }
  }
}
</style>
