<template>
  <li class="layout-topbar-action-item" @click="handleClick">
    <div class="p-d-flex p-flex-row p-ai-center">
      <Avatar
        v-if="false"
        shape="circle"
        size="large"
        :image="data.display.avatar"
        style="background-color: var(--primary-color); color: var(--primary-color-text)"
      >
        <img
          class="avatar-image"
          v-if="data.display.avatar && imageLoaded"
          :src="data.display.avatar"
          @error="imageLoaded = false"
        />
        <span v-else class="p-text-bold p-text-uppercase" style="font-size: 1rem">{{ avatarLabel() }}</span>
      </Avatar>
      <div class="p-d-flex p-flex-column p-ml-3" style="flex-grow: 1">
        <div class="p-d-flex p-ai-center p-jc-between p-mb-1">
          <small class="fs-small"
            ><span :class="{ 'p-text-bold': data.unread }">{{ data.display.name }}</span> {{ caption }}</small
          >
        </div>
        <small>{{ data.timesince }} ago</small>
      </div>
      <Badge v-show="data.unread"></Badge>
    </div>
  </li>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

import useNotifications from '../../hooks/useNotifications'
import { types } from '../../store/mutation-types'

const props = defineProps({
  data: { type: Object, required: true }
})

const store = useStore()
const { markAsRead } = useNotifications()
const imageLoaded = ref(true)

const handleClick = async (e) => {
  markAsRead(props.data.id).then((data) => {
    if (data.data.count) {
      store.dispatch(types.NOTIFICATIONS_FETCH)
    }
  })
  if (window.Intercom) {
    window.Intercom('show')
  }
}

const avatarLabel = () => {
  const { name } = props.data.display || {}
  if (!name) return 'G'
  if (name.length <= 3) return name
  return name
    .split(' ')
    .map((p) => p[0])
    .join('')
    .slice(0, 3)
}

// eslint-disable-next-line no-unused-vars
const caption = computed(() => {
  const message = props.data.display.message
  if (message?.length > 45) {
    return `${message?.slice(0, 48)}...`
  } else return message
})
</script>

<style scoped>
.avatar-image {
  height: 100% !important;
  width: 100% !important;
}
</style>
